import { AuthUser } from 'src/app/auth/models/auth-user.model';

export class UserOperations {
  public static idTokenToUserData(idToken: string): AuthUser {
    const decodedToken = JSON.parse(atob(idToken.split('.')[1]));
    const data: AuthUser = {
      aud: decodedToken.aud || '',
      company: decodedToken['custom:company'],
      country: decodedToken['custom:country'],
      exp: decodedToken.exp,
      firstName: decodedToken.given_name || '',
      iat: decodedToken.iat,
      iss: decodedToken.iss,
      lastName: decodedToken.family_name || '',
      email: decodedToken.email || '',
      nickname: decodedToken.nickname || '',
      nonce: decodedToken.nonce,
      orgCode: decodedToken['custom:org_code'],
      gid: decodedToken['custom:gid']
    };
    return data;
  }
}
