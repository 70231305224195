<div class="container" [class.most-relevant]="design === 'most-relevant'" [class.faq]="design === 'faq'">
  <div class="main-tile" (click)="toggleAccordion()" [ngClass]="{ collapsed: collapsed }">
    <div class="title">{{ title | translate }}</div>
    <mat-icon [ngClass]="{ collapsed: collapsed }">
      {{ collapsed ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}
    </mat-icon>
  </div>

  <div *ngIf="!collapsed" class="detail-container">
    <ng-content></ng-content>
  </div>
</div>
