<div class="container" [ngClass]="tenantService.getTenant().toString()">
  <div class="left">
    <p>
      <b>{{ 'home.whats-new' | translate }}</b>
    </p>
  </div>
  <div class="right">
    <app-loading-indicator *ngIf="isLoading" color="var(--primary900)" size="100px"></app-loading-indicator>
    <div class="info-section" *ngFor="let item of whatsNewItems">
      <img *ngIf="tenantService.isTenantSiemens() && item.id === 'whatsNew|section1'" class="icon" src="./assets/svgs/welcome-page/icon1.svg" />
      <img *ngIf="tenantService.isTenantSiemens() && item.id === 'whatsNew|section2'" class="icon" src="./assets/svgs/welcome-page/icon2.svg" />
      <img *ngIf="tenantService.isTenantSiemens() && item.id === 'whatsNew|section3'" class="icon" src="./assets/svgs/welcome-page/icon3.svg" />
      <img *ngIf="(tenantService.isTenantSE() || tenantService.isTenantSHS()) && item.id === 'whatsNew|section1'" class="icon" src="./assets/svgs/welcome-page/icon1-white.svg" />
      <img *ngIf="(tenantService.isTenantSE() || tenantService.isTenantSHS()) && item.id === 'whatsNew|section2'" class="icon" src="./assets/svgs/welcome-page/icon2-white.svg" />
      <img *ngIf="(tenantService.isTenantSE() || tenantService.isTenantSHS()) && item.id === 'whatsNew|section3'" class="icon" src="./assets/svgs/welcome-page/icon3-white.svg" />
      <mat-icon class="toggle-button" (click)="toggleInfo(item.id)" [style.transform]="isHiddenMap.get(item.id) ? 'rotate(180deg)' : 'rotate(0deg)'"> expand_less </mat-icon>
      <p class="title" (click)="toggleInfo(item.id)">{{ item.title | translate }}</p>
      <ul *ngIf="!isHiddenMap.get(item.id)">
        <!-- Here Content -->
        <li *ngIf="item.content">
          <ng-container>
            <p [innerHTML]="item.content | translate"></p>
          </ng-container>
        </li>
        <!-- Here Links -->
        <li *ngFor="let link of item.links">
          <a *ngIf="link.linkUrl" [href]="link.linkUrl">{{ link.label | translate }}</a>
          <ng-container *ngIf="!link.linkUrl">
            <p [innerHTML]="link.label | translate"></p>
          </ng-container>
        </li>
      </ul>
    </div>
  </div>
</div>
