import { TENANT } from '../Tenant.enum';
import { IQuestion } from './IQuestion';

export class ICategory {
  id: number;
  title: CategoryEnum;
  selectedImage?: string;
  hoveredImage?: string;
  idleImage?: string;
  questions?: IQuestion[];
  selected?: boolean;
  // isGeneral: number;
  company: TENANT;
}

export enum CategoryEnum {
  GENERAL = 'General Information & First Steps',
  EMPLOYEE = 'Employee Topics',
  MANAGER = 'Manager Topics',
  ABSENCE = 'Absence Topics (available for some countries)',
  REPORTING = 'Reporting Topics',
  COUNTRY = 'Country Specific Training Materials'
}
