import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SupportContactsService } from 'src/app/shared/services/support-contacts.service';
import { LoadingService } from '../../shared/services/loading.service';
import { AddRegionComponent } from './add-region/add-region.component';
import { AddSupportContactComponent } from './add-support-contacts/add-support-contacts.component';
import { ISupportContactExtended } from './../../shared/interfaces/ISupportContact';
import { IRegionItem } from './../../shared/interfaces/IRegion';

@Component({
  selector: 'app-cms-support-contacts',
  templateUrl: './cms-support-contacts.component.html',
  styleUrls: ['./cms-support-contacts.component.scss']
})
export class CmsSupportContactsComponent implements OnInit {
  supportContactItems: ISupportContactExtended[];
  regions: IRegionItem[];
  itemsPerRegion: ISupportContactExtended[];

  constructor(public dialog: MatDialog, private supportContactsService: SupportContactsService, private loadingService: LoadingService) {}

  async ngOnInit(): Promise<void> {
    this.loadingService.present();
    this.supportContactItems = await this.supportContactsService.getAllForCMS();
    this.regions = await this.supportContactsService.getAllRegions();
    this.loadingService.dismiss();
  }

  openNewSupportContactDialogue(): void {
    this.dialog.open(AddSupportContactComponent, {
      minWidth: '600px',
      width: '70vw',
      maxHeight: '95vh',
      data: { isNew: true, regions: this.regions }
    });
  }

  openNewAddRegionDialogue(): void {
    this.dialog.open(AddRegionComponent, {
      minWidth: '600px',
      width: '70vw',
      maxHeight: '95vh'
    });
  }

  sortOrder(): void {
    if (this.itemsPerRegion !== undefined) {
      this.itemsPerRegion.forEach(key => {
        if (key !== undefined) {
          const hItem = key;
          key.country = hItem.siemens ? hItem.siemens?.country : hItem.siemensenergy ? hItem.siemensenergy?.country : hItem.shs?.country;
        }
      });
      this.itemsPerRegion.sort((a, b) => a.country.localeCompare(b.country));
    }
  }

  getItemsByRegion(category: string): ISupportContactExtended[] {
    if (this.supportContactItems) {
      this.itemsPerRegion = this.supportContactItems.filter(
        contact => contact.siemens?.region === category || contact.shs?.region === category || contact.siemensenergy?.region === category
      );
    }
    this.sortOrder();
    return this.itemsPerRegion;
  }
}
