import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TopicsTileInterface } from '../../interfaces/topics-tile.interface';

@Component({
  selector: 'app-topic-tiles-container',
  templateUrl: './topic-tiles-container.component.html',
  styleUrl: './topic-tiles-container.component.scss'
})
export class TopicTilesContainerComponent {
  @Input() tiles: TopicsTileInterface[];
  @Input() size: 'small' | 'large' = 'small';
  @Input() asLink: boolean;
  @Output() tileClicked = new EventEmitter<{ tile: TopicsTileInterface; tileIndex: number }>();

  protected onTileClicked(tile: TopicsTileInterface, tileIndex: number): void {
    this.tileClicked.emit({ tile, tileIndex });
  }
}
