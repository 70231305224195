<div class="container" mat-dialog-content>
  <mat-icon class="close-icon" (click)="closeDialog()">close</mat-icon>
  <h2>{{ 'support.title' | translate }}</h2>

  <h3>{{ 'support.subtitle-hr-admin' | translate }}:</h3>
  <div>
    <div class="regions">
      <mat-form-field class="search-field" appearance="outline">
        <mat-label>
          <mat-icon class="search-icon">search</mat-icon>
          {{ 'general.search' | translate }}
        </mat-label>
        <input matInput type="text" [(ngModel)]="searchValue" (ngModelChange)="onSearch($event)" />
        <button *ngIf="searchValue" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <app-action-button
        *ngFor="let contactRegion of contactRegions"
        [design]="regionSelected === contactRegion.region ? 'filled' : 'stroke'"
        (click)="toggleRegion(contactRegion.region)"
        class="region-button"
      >
        {{ contactRegion.region | translate }}
      </app-action-button>

      <div class="contact-list-container">
        <p *ngIf="displayedContacts.length === 0 && !isSearching">{{ 'support.select-region' | translate }}</p>
        <p *ngIf="displayedContacts.length === 0 && isSearching">{{ 'support.no-country' | translate }}</p>

        <div *ngIf="displayedContacts.length" class="contact-list">
          <div class="contact-entry" *ngFor="let contactDetails of displayedContacts">
            <p class="contact-entry-item"><img class="flag" src="{{ contactDetails.flagUrl }}" /></p>
            <p class="contact-entry-item">{{ contactDetails.country }}</p>
            <p class="contact-entry-item">{{ contactDetails.organization }}</p>
            <p class="contact">
              <a [href]="(contactDetails.contact.includes('@') ? 'mailto:' : '') + contactDetails.contact">
                {{ contactDetails.contact }}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Here Content -->
  <div *ngIf="supportContent">
    <ng-container>
      <p [innerHTML]="supportContent | translate"></p>
    </ng-container>
  </div>
</div>
