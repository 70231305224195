import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-gt-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  protected footerLinks = [
    { link: 'https://siemens.com/global/en/general/legal.html', name: 'Corporate Info' },
    { link: 'https://siemens.com/global/en/general/privacy-notice.html', name: 'Privacy Notice' },
    { link: 'https://siemens.com/global/en/general/cookie-notice.html', name: 'Cookie Notice' },
    { link: 'https://siemens.com/global/en/general/terms-of-use.html', name: 'Terms of Use' },
    { link: 'https://siemens.com/global/en/general/digital-id.html', name: 'Digital ID' }
  ];
  protected homepageLink = 'https://www.siemens.com/global/en.html';
  protected currentYear = new Date().getFullYear();

  constructor(private router: Router) {}

  public handleClick(event: Event): void {
    event.preventDefault();
    const target = event.target as HTMLAnchorElement;
    if (target.href.startsWith('/')) {
      void this.router.navigate([target.href], { queryParamsHandling: 'preserve' });
    } else {
      window.open(target.href, '_blank');
    }
  }
}
