import { Component } from '@angular/core';
import { TranslationEditorService } from '../services/translation-editor.service';

@Component({
  selector: 'app-translations',
  templateUrl: './translations.component.html',
  styleUrls: ['./translations.component.scss']
})
export class TranslationsComponent {
  translations: any;

  constructor(public translationEditor: TranslationEditorService) {
    this.translations = translationEditor.translations;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  createNewTopic(parentTopic: any): void {
    this.translationEditor.createNewTopic(parentTopic);
  }

  uploadTranslations(): void {
    this.translationEditor.uploadChanges();
  }
}
