import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-translation-new-topic-dialog',
  templateUrl: './translation-new-topic-dialog.component.html',
  styleUrls: ['./translation-new-topic-dialog.component.scss']
})
export class TranslationNewTopicDialogComponent {
  topicTitle: string;

  constructor(public dialogRef: MatDialogRef<TranslationNewTopicDialogComponent>) {}

  closeDialog(): void {
    this.dialogRef.close();
  }
}
