import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { IHelpItem } from '../shared/interfaces/IHelpItem';
import { TenantService } from '../shared/services/tenant.service';
import { HelpItemService } from '../shared/services/help-item.service';

@Component({
  selector: 'app-most-relevant',
  templateUrl: './most-relevant.component.html',
  styleUrls: ['./most-relevant.component.scss']
})
export class MostRelevantComponent implements OnInit {
  constructor(private http: HttpClient, private tenantService: TenantService, private helpItemService: HelpItemService) {}
  items: IHelpItem[];
  isLoading = true;
  @Input() category: any;
  company = this.tenantService.getTenantString();

  ngOnInit(): void {
    this.helpItemService.getMostRelevantHelpItems(this.company).subscribe(result => {
      this.isLoading = false;
      this.items = result.sort((a, b) => a.sortOrderMostRelevant - b.sortOrderMostRelevant);
    });
  }
}
