import { TENANT } from 'src/app/shared/Tenant.enum';
import { Component, OnInit } from '@angular/core';
import { WhatsNewService } from 'src/app/shared/services/whats-new.service';
import { TenantService } from '../shared/services/tenant.service';
import { WhatsNewItem, WhatsNewItemExtended } from './../shared/interfaces/IWhatsNewExtended';

@Component({
  selector: 'app-critical-info',
  templateUrl: './critical-info.component.html',
  styleUrls: ['./critical-info.component.scss']
})
export class CriticalInfoComponent implements OnInit {
  whatsNewItemsExtended: WhatsNewItemExtended[];
  isHiddenMap = new Map<string, boolean>();
  TENANT: TENANT;
  whatsNewItems: WhatsNewItem[] = [];
  isLoading = true;

  constructor(public tenantService: TenantService, private whatsNewService: WhatsNewService) {}

  async ngOnInit(): Promise<void> {
    this.whatsNewItemsExtended = await this.whatsNewService.getAllForCMS();
    this.TENANT = this.tenantService.getTenant();
    this.createArray();
    this.isLoading = false;
  }

  createArray(): void {
    this.whatsNewItemsExtended.forEach(item => {
      if (this.TENANT === TENANT.Siemens && item.siemens !== undefined) {
        if (item.siemens.title) {
          if (item.siemens.id === 'whatsNew|section3') {
            item.siemens.links.sort((a, b) => a.sortOrder - b.sortOrder);
            this.whatsNewItems.push(item.siemens);
          } else {
            this.whatsNewItems.push(item.siemens);
          }
        }
      } else if (this.TENANT === TENANT.SE && item.siemensenergy !== undefined) {
        if (item.siemensenergy.title) {
          if (item.siemensenergy.id === 'whatsNew|section3') {
            item.siemensenergy.links.sort((a, b) => a.sortOrder - b.sortOrder);
            this.whatsNewItems.push(item.siemensenergy);
          } else {
            this.whatsNewItems.push(item.siemensenergy);
          }
        }
      } else if (this.TENANT === TENANT.SHS && item.shs !== undefined) {
        if (item.shs.title) {
          if (item.shs.id === 'whatsNew|section3') {
            item.shs.links.sort((a, b) => a.sortOrder - b.sortOrder);
            this.whatsNewItems.push(item.shs);
          } else {
            this.whatsNewItems.push(item.shs);
          }
        }
      }
    });
    this.whatsNewItems.sort((a, b) => Number(a.id.charAt(a.id.length - 1)) - Number(b.id.charAt(b.id.length - 1)));
  }

  toggleInfo(id: string): void {
    const isHidden = this.isHiddenMap.get(id) ?? false;
    this.isHiddenMap.set(id, !isHidden);
  }
}
