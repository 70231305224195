import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class TokenInjector implements HttpInterceptor {
  // Add here the URLs of webpages where we do not want to add the Bearer Authorization header.
  private readonly tokenBlacklist = [
    'https://wd-help-assets-bucket-dev.s3.eu-central-1.amazonaws.com',
    'https://wd-help-assets-bucket-prod.s3.eu-central-1.amazonaws.com',
    'https://dev.authproxy.siemens.cloud/',
    'https://prod.authproxy.siemens.cloud/'
  ];

  constructor(private auth: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.urlInTokenBlacklist(request.url)) {
      return this.auth.getIdToken().pipe(
        switchMap(token => {
          if (token) {
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${token}`
              }
            });
          }
          return next.handle(request);
        })
      );
    }

    return next.handle(request);
  }

  private urlInTokenBlacklist(url: string): boolean {
    return this.tokenBlacklist.some(whiteUrl => url.indexOf(whiteUrl) > -1);
  }
}
