<div *ngIf="item">
  <div class="container">
    <app-accordion [title]="item.title" design="most-relevant" [attr.data-id]="item.id">
      <div class="tenants">
        <div class="tenant" *ngIf="extendedItem.siemens" [class.active]="selectedTenant === TENANT.Siemens" (click)="tenantSelected(TENANT.Siemens)">
          <img src="./assets/svgs/logo-siemens.svg" />
        </div>
        <div class="tenant" *ngIf="extendedItem.shs" [class.active]="selectedTenant === TENANT.SHS" (click)="tenantSelected(TENANT.SHS)">
          <img src="./assets/svgs/logo-healthineers.svg" />
        </div>
        <div class="tenant" *ngIf="extendedItem.siemensenergy" [class.active]="selectedTenant === TENANT.SE" (click)="tenantSelected(TENANT.SE)">
          <img src="./assets/svgs/logo-energy-no-padding.svg" />
        </div>
      </div>

      <app-action-button class="share-button" (click)="copyShareLink()" [design]="showJustShared ? 'stroke' : 'filled'">
        <span *ngIf="!showJustShared"><mat-icon>content_copy</mat-icon> {{ 'help-items.share' | translate }} (Tenant: {{ selectedTenant }})</span>
        <span *ngIf="showJustShared"><mat-icon>checkmark</mat-icon> {{ 'help-items.copied' | translate }}</span>
      </app-action-button>

      <div class="detail-container">
        <div *ngIf="item.description || item.description === ''">
          <div [innerHTML]="item.description"></div>
        </div>
        <div class="process-items">
          <!-- Videos -->
          <ng-container *ngIf="item.videos.length > 0">
            <h3>{{ 'admin-page.help-topics.edit.videos-title' | translate }}</h3>
            <div *ngFor="let video of item.videos; let i = index" [attr.data-index]="i">
              <div>
                <div>
                  <h3>{{ video.title }}</h3>

                  <div class="video-container">
                    <app-videoplayer [videolink]="video.url" [autoplay]="false"> </app-videoplayer>
                    <div *ngIf="video.label" class="video-label">{{ video.label }}</div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <!-- Links -->
          <ng-container *ngIf="item.links.length > 0">
            <h3>{{ 'admin-page.help-topics.edit.links-title' | translate }}</h3>
            <div *ngFor="let link of item.links; let i = index" [attr.data-index]="i" class="link-items">
              <div>
                <a href="{{ link.linkUrl }}">{{ link.label }}</a>
              </div>
            </div>
          </ng-container>
          <!-- Add Ons -->
          <ng-container *ngIf="item.addOns.length > 0">
            <h3>{{ 'admin-page.help-topics.edit.addOn-title' | translate }}</h3>
            <div *ngFor="let addOn of item.addOns; let i = index" [attr.data-index]="i">
              <div>
                <app-country-add-on [countryName]="addOn.label" [countryCode]="addOn.country" [addOnUrls]="addOn.addOnUrls"> </app-country-add-on>
              </div>
            </div>
          </ng-container>
        </div>

        <div class="action-buttons">
          <app-action-button design="stroke" fill="primary" (click)="openItemEditor()">
            <mat-icon class="button-icon">edit</mat-icon><span>{{ 'admin-page.help-topics.edit.buttons.edit' | translate }}</span>
          </app-action-button>

          <app-action-button design="stroke" fill="warn" (click)="delete(item)">
            <mat-icon class="button-icon">delete</mat-icon><span>{{ 'admin-page.help-topics.edit.buttons.delete' | translate }}</span>
          </app-action-button>
        </div>
      </div>
    </app-accordion>
  </div>
</div>
