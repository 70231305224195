<div class="container">
  <div>
    <a [routerLink]="'/admin'" class="container__back">
      <mat-icon class="icon-display">keyboard_arrow_left</mat-icon>
      {{ 'admin-page.support-contact.buttons.back-button' | translate }}
    </a>
  </div>
  <div class="main">
    <h3>{{ 'admin-page.support-contact.title' | translate }}</h3>
    <button mat-button (click)="openNewSupportContactDialogue()">
      {{ 'admin-page.support-contact.buttons.add-new' | translate }}
    </button>
    <button class="add-region" mat-button (click)="openNewAddRegionDialogue()">
      {{ 'admin-page.support-contact.buttons.add-new-region' | translate }}
    </button>
  </div>
  <p>{{ 'admin-page.support-contact.text' | translate }}</p>

  <!-- Show content editor -->
  <div *ngFor="let item of supportContactItems">
    <app-edit-support-contacts
      *ngIf="item.siemens?.id === 'contact|text' || item.shs?.id === 'contact|text' || item.siemensenergy?.id === 'contact|text'"
      [SupportContactExtended]="item"
      [Regions]="this.regions"
    >
    </app-edit-support-contacts>
  </div>
  <!-- Loop ALL contacts -->
  <div class="category-container" *ngFor="let item of this.regions">
    <h2>
      {{ item.region }}
    </h2>
    <div>
      <app-edit-support-contacts *ngFor="let item of getItemsByRegion(item.region)" [SupportContactExtended]="item" [Regions]="this.regions"> </app-edit-support-contacts>
    </div>
  </div>
</div>
