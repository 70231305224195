import { CategoryEnum } from 'src/app/shared/interfaces/ICategory';
import { IQuestion, IQuestionExtended } from 'src/app/shared/interfaces/IQuestion';
import * as XLSX from 'xlsx';

export class CmsFaqOperations {
  public static exportAsExcel(questions: IQuestionExtended[]): void {
    if (!questions) {
      console.error('No questions available to export.');
      return;
    }

    const siemensData: any[][] = [['Id', 'Question', 'Answer', 'Category']];
    const shsData: any[][] = [['Id', 'Question', 'Answer', 'Category']];
    const siemensenergyData: any[][] = [['Id', 'Question', 'Answer', 'Category']];

    questions.forEach(question => {
      if (question.siemens) {
        siemensData.push([question.siemens.id, question.siemens.question, question.siemens.answer, CategoryEnum[question.siemens.category]]);
      }
      if (question.shs) {
        shsData.push([question.shs.id, question.shs.question, question.shs.answer, CategoryEnum[question.shs.category]]);
      }
      if (question.siemensenergy) {
        siemensenergyData.push([question.siemensenergy.id, question.siemensenergy.question, question.siemensenergy.answer, CategoryEnum[question.siemensenergy.category]]);
      }
    });

    const workbook = XLSX.utils.book_new();

    const siemensSheet = XLSX.utils.aoa_to_sheet(siemensData);
    const shsSheet = XLSX.utils.aoa_to_sheet(shsData);
    const siemensenergySheet = XLSX.utils.aoa_to_sheet(siemensenergyData);

    XLSX.utils.book_append_sheet(workbook, siemensSheet, 'Siemens');
    XLSX.utils.book_append_sheet(workbook, shsSheet, 'SHS');
    XLSX.utils.book_append_sheet(workbook, siemensenergySheet, 'SiemensEnergy');

    XLSX.writeFile(workbook, 'Workday_Help_FAQ_Export.xlsx');
  }

  public static createObjectsForCsvDownload(questions: IQuestionExtended[]): { siemensData: IQuestion[]; shsData: IQuestion[]; siemensenergyData: IQuestion[] } {
    const siemensData: IQuestion[] = [];
    const shsData: IQuestion[] = [];
    const siemensenergyData: IQuestion[] = [];
    questions.forEach((question: IQuestionExtended) => {
      if (question.siemens) {
        siemensData.push(question.siemens);
      }
      if (question.shs) {
        shsData.push(question.shs);
      }
      if (question.siemensenergy) {
        siemensenergyData.push(question.siemensenergy);
      }
    });
    return {
      siemensData,
      shsData,
      siemensenergyData
    };
  }
}
