import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { WhatsNewService } from 'src/app/shared/services/whats-new.service';
import { ActivatedRoute } from '@angular/router';
import { LoadingService } from '../../shared/services/loading.service';
import { WhatsNewItemExtended } from './../../shared/interfaces/IWhatsNewExtended';

@Component({
  selector: 'app-cms-whats-new',
  templateUrl: './cms-whats-new.component.html',
  styleUrls: ['./cms-whats-new.component.scss']
})
export class CmsWhatsNewComponent implements OnInit, OnDestroy {
  whatsNewItems: WhatsNewItemExtended[];
  sectionParts = [
    { id: 'whatsNew|section1', text: 'Section part 1' },
    { id: 'whatsNew|section2', text: 'Section part 2' },
    { id: 'whatsNew|section3', text: 'Section part 3' }
  ];
  private destroy$ = new Subject<void>();

  constructor(private whatsNewService: WhatsNewService, private loadingService: LoadingService, private route: ActivatedRoute, private toast: ToastrService) {}

  async ngOnInit(): Promise<void> {
    this.loadingService.present();
    this.whatsNewItems = await this.whatsNewService.getAllForCMS();
    this.whatsNewItems.forEach(key => {
      const tenantTitle = key.siemens ? key.siemens.title : key.shs ? key.shs.title : key.siemensenergy.title;
      key.id = tenantTitle.replace(/\W/g, '');
    });
    this.loadingService.dismiss();
    this.getHighlightedTopicId();
  }

  private getHighlightedTopicId() {
    this.route.queryParams
      .pipe(
        filter(params => params.id !== undefined),
        takeUntil(this.destroy$)
      )
      .subscribe(params => {
        const id: string = params.id;

        const highlightedItem = this.whatsNewItems.find(item => item.id === id);
        if (!highlightedItem) {
          this.toast.warning('Could not find help topic with specified id.');
          console.log('Could not find help topic with specified id.');
          return;
        }

        setTimeout(() => {
          // Timeout in order to wait until the page has fully loaded before querying the html element
          const element: HTMLElement = document.querySelector('app-edit-whats-new[data-id="' + highlightedItem.id + '"] app-accordion > div');
          if (element) {
            // Open up the highlighted element
            (element.firstElementChild as HTMLElement).click();
            setTimeout(() => {
              element.scrollIntoView();
            }, 1000);
          }
        }, 0);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
