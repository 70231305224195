import { Component } from '@angular/core';
import { CategoryEnum } from '../shared/interfaces/ICategory';
import { TenantService } from '../shared/services/tenant.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  CategoryEnum = CategoryEnum;

  constructor(public tenantService: TenantService) {}
}
