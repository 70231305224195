import { CategoryEnum } from './ICategory';

export class IQuestion {
  id: string;
  question: string;
  answer: string;
  category: CategoryEnum;
}

export interface IQuestionExtended {
  siemens?: IQuestion;
  shs?: IQuestion;
  siemensenergy?: IQuestion;
}
