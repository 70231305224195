import { EventEmitter, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslationNewItemDialogComponent } from '../translations/translation-new-item-dialog/translation-new-item-dialog.component';
import { TranslationNewTopicDialogComponent } from '../translations/translation-new-topic-dialog/translation-new-topic-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class TranslationEditorService {
  onUpdate = new EventEmitter<void>();

  // TODO: Load translations from S3 Bucket
  translations: any = {
    home: {
      title: 'Home'
    }
  };

  constructor(public dialog: MatDialog) {}

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  createNewTopic(parentTopic: any): void {
    const dialogRef = this.dialog.open(TranslationNewTopicDialogComponent, {
      width: '50%'
    });

    dialogRef.afterClosed().subscribe((result: string) => {
      if (result) {
        result = TranslationEditorService.getFormattedKey(result);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        if (!Object.keys(parentTopic).includes(result)) {
          // Prevent overriding of previous topic if it already existed
          parentTopic[result] = {};

          this.onUpdate.emit();
        }
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  createNewItem(parentTopic: any): void {
    const dialogRef = this.dialog.open(TranslationNewItemDialogComponent, {
      width: '50%'
    });

    dialogRef.afterClosed().subscribe((result: { key: string; value: string }) => {
      if (result) {
        const key = TranslationEditorService.getFormattedKey(result.key);
        const value = result.value ?? '';

        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        if (!Object.keys(parentTopic).includes(key)) {
          // Prevent overriding of previous topic if it already existed
          parentTopic[key] = value;

          this.onUpdate.emit();
        }
      }
    });
  }

  uploadChanges(): void {
    // TODO: Upload "this.translations" to the S3 Bucket here
  }

  /**
   * Formats a key correctly: for instance " Hello World " gets transformed to "hello-world"
   */
  private static getFormattedKey(unformattedKey: string): string {
    // remove whitespaces at beginning / end of str
    let key = unformattedKey.trim();
    // replace whitespaces with '-'
    key = key.replace(/\s/g, '-');
    key = key.toLowerCase();
    return key;
  }
}
