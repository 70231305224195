<app-accordion *ngFor="let topic of translationTopics" [title]="topic">
  <app-translation-accordion [translations]="translations[topic]"></app-translation-accordion>

  <div class="accordion-footer">
    <app-action-button (click)="createNewItem(translations[topic])">translations.button.add-item</app-action-button>
    <app-action-button (click)="createNewTopic(translations[topic])">translations.button.add-topic</app-action-button>
    <!-- <app-action-button (click)="deleteTopic(translations[topic])" design="stroke" fill="warn"> -->
    <!-- translations.button.delete-topic</app-action-button> -->
  </div>
</app-accordion>

<div class="items-container">
  <div class="item" *ngFor="let item of translationItems">
    <p class="key">{{ item.key }}</p>

    <mat-form-field class="value" appearance="outline">
      <!--      <mat-label>{{item.key}}</mat-label>-->
      <textarea matInput [value]="item.value"></textarea>
    </mat-form-field>
  </div>
</div>
