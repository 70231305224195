import { Injectable } from '@angular/core';

type AllowedTypes = string | number | boolean | null;
type AllowedObject = { [key: string]: AllowedTypes };
@Injectable({
  providedIn: 'root'
})
export class CsvHelperService {
  public downloadRecordsAsCsv<T>(data: T[], filename = 'csv_download.csv'): void {
    const allowedData = this._convertToAllowedObject(data);
    this._downloadCsv(allowedData, filename);
  }

  public escapeQuotationMarks(text: string): string {
    return text.replace(/"/g, '""');
  }

  private _downloadCsv(data: AllowedObject[], filename = 'csv_download.csv'): void {
    const csvData = this._convertToCSV(data);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const downloadLink = document.createElement('a');
    const url = URL.createObjectURL(blob);

    downloadLink.href = url;
    filename = filename.endsWith('.csv') ? filename : `${filename}.csv`;
    downloadLink.setAttribute('download', filename);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  private _convertToCSV(data: Record<string, string | number | boolean | null>[]): string {
    const header = Object.keys(data[0]).join(',');
    const rows = data.map(obj => Object.values(obj).join(','));
    return `${header}\n${rows.join('\n')}`;
  }

  private _convertToAllowedObject<T>(data: T[]): AllowedObject[] {
    return data.map(item => {
      const allowedObject: AllowedObject = {};
      for (const key in item) {
        if (Object.prototype.hasOwnProperty.call(item, key)) {
          allowedObject[key] = (item as any)[key];
        }
      }
      return allowedObject;
    });
  }
}
