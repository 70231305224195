<div class="add-contact-dialogue" mat-dialog-content>
  <div class="close-dialogue-icon" mat-icon (click)="closeNewSupportContactDialogue()">
    <i class="material-icons">close</i>
  </div>
  <h2>{{ 'admin-page.support-contact.add.title-add-region' | translate }}</h2>
  <div class="edit-container">
    <div class="main-tile">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'admin-page.support-contact.add.region' | translate }}</mat-label>
        <input type="text" matInput required [(ngModel)]="region" />
        <mat-error *ngIf="!region || region.trim().length === 0">
          {{ 'admin-page.warning' | translate }}
        </mat-error>
      </mat-form-field>
    </div>

    <app-action-button class="save-button" [disabled]="!isSaveEnabled" (click)="createRegion()">
      {{ 'general.save' | translate }}
    </app-action-button>
  </div>
</div>
