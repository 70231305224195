import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TopicsTileInterface } from '../../../interfaces/topics-tile.interface';

@Component({
  selector: 'app-topics-tile',
  templateUrl: './topics-tile.component.html',
  styleUrls: ['./topics-tile.component.scss']
})
export class TopicsTileComponent {
  @Input() tile: TopicsTileInterface;
  @Input() size: 'small' | 'large' = 'large';
  @Output() itemClicked = new EventEmitter<TopicsTileInterface>();

  protected tileClicked(tile: TopicsTileInterface): void {
    this.itemClicked.emit(tile);
  }
}
