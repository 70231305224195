import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil, tap } from 'rxjs';
import { TopicsTileInterface } from '../shared/interfaces/topics-tile.interface';
import { HelpItemService } from '../shared/services/help-item.service';

@Component({
  selector: 'app-help-topics',
  templateUrl: './help-topics.component.html',
  styleUrls: ['./help-topics.component.scss']
})
export class HelpTopicsComponent implements OnInit, OnDestroy {
  protected selectedTab = 0;
  protected tiles: TopicsTileInterface[];
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private helpTopicsService: HelpItemService) {}

  ngOnInit(): void {
    this.getHelpTopics();
  }

  private getHelpTopics(): void {
    this.helpTopicsService
      .fetchHelpTiles()
      .pipe(
        tap(value => (this.tiles = value)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
