import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Store } from '@ngxs/store';
import { filter, map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UserState } from '../store';
import { UserRoleEnum } from '../../shared/enums/UserRoleEnum';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard {
  private readonly unauthorizedUrl = '/unauthorized';

  constructor(private router: Router, private store: Store) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const allowedRoles: UserRoleEnum[] = route.data?.rolesAllowed ?? [];

    return this.store.select(UserState.role).pipe(
      // Filter if role undefined
      filter(role => !!role),
      map(role => role !== undefined && allowedRoles.includes(role)),
      tap(res => {
        if (!res) {
          void this.router.navigate([this.unauthorizedUrl]);
        }
      })
    );
  }
}
