import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { IHelpItem } from '../interfaces/IHelpItem';
import { environment } from '../../../environments/environment';
import { TopicsTileInterface } from '../interfaces/topics-tile.interface';
import { TENANT } from '../Tenant.enum';
import { IHelpItemExtended } from '../interfaces/IHelpItemExtended';
import { CategoryEnum } from '../interfaces/ICategory';

@Injectable()
export class HelpItemService {
  private categoriesItems = new Map<string, IHelpItem[]>();
  private mostRelevant?: IHelpItem[];

  constructor(public http: HttpClient) {}

  getHelpItemByCategory(company: string, category: string): Observable<IHelpItem[]> {
    if (this.categoriesItems.has(category)) {
      return of(this.categoriesItems.get(category));
    }

    const URL = `${environment.api.backend}help-item?tenant=${company}&category=${encodeURIComponent(category)}`;
    return this.http.get<IHelpItem[]>(URL).pipe(tap(items => this.categoriesItems.set(category, items)));
  }

  getMostRelevantHelpItems(company: string): Observable<IHelpItem[]> {
    if (this.mostRelevant) {
      return of(this.mostRelevant);
    }

    const URL = `${environment.api.backend}help-item/most-relevant?tenant=${company}`;
    return this.http.get<IHelpItem[]>(URL).pipe(tap(items => (this.mostRelevant = items)));
  }

  fetchHelpTiles(): Observable<TopicsTileInterface[]> {
    return of([
      {
        title: CategoryEnum.GENERAL,
        icon: 'assets/images/help/General-default.png',
        iconHover: 'assets/images/help/General-select.png',
        url: '/help-topics/general'
      },
      {
        title: CategoryEnum.EMPLOYEE,
        icon: 'assets/images/help/Employee-default.png',
        iconHover: 'assets/images/help/Employee-select.png',
        url: '/help-topics/employee-topics'
      },
      {
        title: CategoryEnum.MANAGER,
        icon: 'assets/images/help/Manager-default.png',
        iconHover: 'assets/images/help/Manager-select.png',
        url: '/help-topics/manager-topics'
      },
      {
        title: CategoryEnum.ABSENCE,
        icon: 'assets/images/help/Absence-default.png',
        iconHover: 'assets/images/help/Absence-select.png',
        url: '/help-topics/absence-topics'
      },
      {
        title: CategoryEnum.REPORTING,
        icon: 'assets/images/help/Reporting-default.png',
        iconHover: 'assets/images/help/Reporting-select.png',
        url: '/help-topics/reporting-topics'
      },
      {
        title: CategoryEnum.COUNTRY,
        icon: 'assets/images/help/Training-default.png',
        iconHover: 'assets/images/help/Training-select.png',
        url: '/help-topics/country-specific'
      }
    ]);
  }

  getHelpITemsByCompany(company: string): Promise<IHelpItem[]> {
    const URL = `${environment.api.backend}help-item?tenant=${company}`;
    return this.http.get<IHelpItem[]>(URL).toPromise();
  }

  getAllForCMS(): Promise<IHelpItemExtended[]> {
    const URL = `${environment.api.backend}help-item/cms`;
    return this.http.get<IHelpItemExtended[]>(URL).toPromise();
  }

  async deleteHelpItem(company: TENANT, itemId: string): Promise<void> {
    const URL = `${environment.api.backend}help-item?tenant=${company}&id=${itemId}`;
    try {
      await this.http.delete(URL).toPromise();
      // this.toast.success('Deleted item.');
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  }

  saveNewHelpItem(tenant: TENANT, item: IHelpItem): Promise<any> {
    const params = {
      tenant,
      language: 'en' // TODO: Make this parameter dynamic
    };
    const URL = `${environment.api.backend}help-item`;
    return this.http.post(URL, item, { params }).toPromise();
  }

  updateHelpItem(tenant: TENANT, item: IHelpItem): Promise<any> {
    const params = {
      tenant,
      language: 'en', // TODO: Make this parameter dynamic
      id: item.id,
      sortOrder: item.sortOrder,
      sortOrderMostRelevant: item.sortOrderMostRelevant,
      updateLinksAlsoSE: false,
      updateLinksAlsoSHS: false
    };
    const URL = `${environment.api.backend}help-item`;
    return this.http.put(URL, item, { params }).toPromise();
  }

  getCategoryUrl(categoryTitle: string): Observable<string | undefined> {
    return this.fetchHelpTiles().pipe(map(tiles => tiles.find(tile => tile.title === categoryTitle)?.url));
  }
}
