import { IWhatsNewLink } from './IWhatsNewLink';

export class WhatsNewItem {
  id: string;
  title: string;
  content?: string;
  links?: IWhatsNewLink[];
}

export interface WhatsNewItemExtended {
  siemens: WhatsNewItem;
  shs: WhatsNewItem;
  siemensenergy: WhatsNewItem;
  id?: string;
}
