<div class="container">
  <div id="main">
    <div>
      <a [routerLink]="'/admin'" class="container__back">
        <mat-icon class="icon-display">keyboard_arrow_left</mat-icon>
        {{ 'admin-page.faq.buttons.back-button' | translate }}
      </a>
    </div>
    <h3>
      {{ 'admin-page.faq.header' | translate }}
    </h3>
    <div class="admin-faq-actions">
      <button class="add-faq-item" mat-button (click)="openNewFaqDialogue()">
        {{ 'admin-page.faq.buttons.add-new' | translate }}
      </button>
      <button class="add-faq-item" mat-button (click)="exportAsCSV(questions)">
        {{ 'admin-page.faq.buttons.export-csv' | translate }}
      </button>
      <button class="add-faq-item" mat-button (click)="CmsFaqOperations.exportAsExcel(questions)">
        {{ 'admin-page.faq.buttons.export-xlsx' | translate }}
      </button>
    </div>
  </div>
  <p>{{ 'admin-page.faq.description' | translate }}</p>

  <!-- SEARCH -->
  <app-faq-search
    [showTextSearch]="true"
    [showCategorySearch]="true"
    [categoryTiles]="tiles"
    [amountOfSearchHits]="searchResult?.length"
    (categoryClicked)="handleCategoryClicked($event.category, $event.categoryIndex)"
    (searchInput)="startSearch($event)"
    (searchCleared)="clearSearch()"
  ></app-faq-search>

  <!-- ALL FAQS per CATEGORY -->
  <div class="category-container" *ngFor="let category of keys">
    <div *ngIf="categories[category]">
      <h2>
        {{ category }}
      </h2>
      <div class="faq-tiles">
        <app-edit-faq *ngFor="let item of getQuestionsByCategory(category)" [extendedQuestion]="item"> </app-edit-faq>
      </div>
    </div>
  </div>
</div>
