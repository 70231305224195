import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CategoryEnum } from 'src/app/shared/interfaces/ICategory';
import { IQuestion, IQuestionExtended } from 'src/app/shared/interfaces/IQuestion';
import { TenantService } from 'src/app/shared/services/tenant.service';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { TENANT } from 'src/app/shared/Tenant.enum';
import { AddFaqComponent } from '../add-faq/add-faq.component';

@Component({
  selector: 'app-edit-faq',
  templateUrl: './edit-faq.component.html',
  styleUrls: ['./edit-faq.component.scss']
})
export class EditFaqComponent implements OnInit {
  @Input() set extendedQuestion(value: IQuestionExtended) {
    this._extendedQuestion = value;
    this.selectedTenant = this.extendedQuestion[TENANT.Siemens] ? TENANT.Siemens : this.extendedQuestion[TENANT.SHS] ? TENANT.SHS : TENANT.SE;
    this.question = value[this.selectedTenant];
  }

  get extendedQuestion(): IQuestionExtended {
    return this._extendedQuestion;
  }
  private _extendedQuestion: IQuestionExtended;
  selectedTenant: TENANT = TENANT.Siemens;
  TENANT = TENANT;
  questionText: string;
  answer: string;
  selectedCategory: CategoryEnum;
  company = this.tenantService.getTenantString();
  question: IQuestion;

  constructor(private http: HttpClient, private toast: ToastrService, private tenantService: TenantService, public dialog: MatDialog) {}

  ngOnInit(): void {
    this.questionText = this.question?.question;
    this.answer = this.question?.answer;
    this.selectedCategory = this.question?.category;
  }

  async deleteQuestion(id: string): Promise<void> {
    const URL = `${environment.api.backend}faq?tenant=${this.selectedTenant}&id=${id}`;

    try {
      await this.http.delete(URL).toPromise();
      this.toast.success('Deleted item.');
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  }

  openEditDialog(): void {
    this.dialog.open(AddFaqComponent, {
      minWidth: '600px',
      width: '70vw',
      maxHeight: '95vh',
      data: { question: this.question, tenant: this.selectedTenant }
    });
  }

  tenantSelected(tenant: TENANT): void {
    this.selectedTenant = tenant;
    this.question = this.extendedQuestion[this.selectedTenant];
  }
}
