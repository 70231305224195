<div class="container">
  <div>
    <a [routerLink]="'/admin'" class="container__back">
      <mat-icon class="icon-display">keyboard_arrow_left</mat-icon>
      {{ 'admin-page.faq.buttons.back-button' | translate }}
    </a>
  </div>
  <div class="main">
    <h3>{{ 'admin-page.user-management.header' | translate }}</h3>
  </div>

  <div class="search-container">
    <mat-form-field appearance="fill">
      <mat-label>{{ 'general.search' | translate }}</mat-label>
      <input matInput [(ngModel)]="searchValue" (ngModelChange)="onSearch()" />
      <mat-icon matSuffix>search</mat-icon>
      <mat-hint>{{ 'admin-page.user-management.search-hint' | translate }}</mat-hint>
    </mat-form-field>

    <app-action-button (click)="openAddNewUser()">{{ 'admin-page.user-management.add-user' | translate }}</app-action-button>
  </div>

  <!-- Header -->
  <div class="user-list">
    <div class="header">
      <p>GID</p>
      <p
        class="userName"
        [ngClass]="{
          'icon-Sort-up-down': sortConfig.by !== 'lastName' || sortConfig.order === 'none',
          'icon-Sort-up': sortConfig.by === 'lastName' && sortConfig.order === 'asc',
          'icon-Sort-down': sortConfig.by === 'lastName' && sortConfig.order === 'desc'
        }"
        (click)="sortUsers('lastName')"
      >
        User name ↕
      </p>
      <p
        class="role-heading"
        [ngClass]="{
          'icon-Sort-up-down': sortConfig.by !== 'role' || sortConfig.order === 'none',
          'icon-Sort-up': sortConfig.by === 'role' && sortConfig.order === 'asc',
          'icon-Sort-down': sortConfig.by === 'role' && sortConfig.order === 'desc'
        }"
        (click)="sortUsers('role')"
      >
        Role ↕
      </p>
    </div>
  </div>

  <!-- Users -->
  <div class="user-list">
    <div *ngFor="let user of displayedUsers">
      <p>{{ user.gid }}</p>
      <p class="userName">{{ user.lastName }}, {{ user.firstName }}</p>

      <mat-form-field class="role" appearance="fill">
        <mat-label>{{ 'admin-page.user-management.role' | translate }}</mat-label>
        <mat-select [value]="user.role" (valueChange)="updateUser(user.gid, user.role, $event, user.firstName, user.lastName)">
          <mat-option *ngFor="let role of UserRoleEnumKeys" [value]="UserRoleEnum[role]">{{ role }}</mat-option>
        </mat-select>
      </mat-form-field>

      <app-action-button fill="warn" design="stroke" (click)="deleteUser(user.gid)">{{ 'general.delete' | translate }}</app-action-button>
    </div>
  </div>
</div>
