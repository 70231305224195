import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { OnDestroy } from '@angular/core';
import { TranslationEditorService } from '../../services/translation-editor.service';

@Component({
  selector: 'app-translation-accordion',
  templateUrl: './translation-accordion.component.html',
  styleUrls: ['./translation-accordion.component.scss']
})
export class TranslationAccordionComponent implements OnInit, OnDestroy {
  @Input() translations: any;
  onUpdate: EventEmitter<void>;
  translationTopics: string[] = [];
  translationItems: { key: string; value: string }[] = [];
  private ngUnsubscribe = new Subject<void>();

  constructor(public translationEditor: TranslationEditorService) {
    this.onUpdate = translationEditor.onUpdate;
  }

  ngOnInit(): void {
    this.refreshTranslations();
    this.onUpdate.subscribe(() => this.refreshTranslations());
  }

  refreshTranslations(): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const translationEntries = Object.entries(this.translations);
    const onlyTopics = translationEntries.filter(([, value]) => typeof value !== 'string');
    this.translationTopics = onlyTopics.map(([key]) => key);

    const onlyItems = translationEntries.filter(([, value]) => typeof value === 'string');
    this.translationItems = onlyItems.map(([key, value]) => {
      return { key, value: value.toString() };
    });

    console.log(this.translationItems);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  createNewTopic(parentTopic: any): void {
    this.translationEditor.createNewTopic(parentTopic);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  createNewItem(parentTopic: any): void {
    this.translationEditor.createNewItem(parentTopic);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // deleteTopic(parentTopic: any): void {
  //   // TODO
  // }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
