<div *ngIf="whatsNewItem">
  <div class="container">
    <app-accordion [title]="whatsNewItem.title" design="faq">
      <div class="tenants">
        <div class="tenant" *ngIf="extendedWhatsNew.siemens" [class.active]="selectedTenant === TENANT.Siemens" (click)="tenantSelected(TENANT.Siemens)">
          <img src="./assets/svgs/logo-siemens.svg" />
        </div>
        <div class="tenant" *ngIf="extendedWhatsNew.shs" [class.active]="selectedTenant === TENANT.SHS" (click)="tenantSelected(TENANT.SHS)">
          <img src="./assets/svgs/logo-healthineers.svg" />
        </div>
        <div class="tenant" *ngIf="extendedWhatsNew.siemensenergy" [class.active]="selectedTenant === TENANT.SE" (click)="tenantSelected(TENANT.SE)">
          <img src="./assets/svgs/logo-energy-no-padding.svg" />
        </div>
      </div>
      <div class="detail-container">
        <div class="process-items">
          <div [innerHTML]="whatsNewItem.content"></div>
        </div>

        <!-- Links -->
        <ng-container *ngIf="whatsNewItem.links?.length > 0">
          <h3>{{ 'admin-page.help-topics.edit.links-title' | translate }}</h3>
          <div *ngFor="let link of whatsNewItem.links; let i = index" [attr.data-index]="i" class="link-items">
            <div>
              <a href="{{ link.linkUrl }}">{{ link.label }}</a>
            </div>
          </div>
        </ng-container>

        <div class="action-buttons">
          <app-action-button design="stroke" fill="primary" (click)="openEditDialog()">
            <mat-icon class="button-icon">edit</mat-icon><span>{{ 'admin-page.help-topics.edit.buttons.edit' | translate }}</span>
          </app-action-button>
        </div>
      </div>
    </app-accordion>
  </div>
</div>
