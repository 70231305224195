<div class="add-contact-dialogue" mat-dialog-content>
  <div class="close-dialogue-icon" mat-icon (click)="closeNewSupportContactDialogue()">
    <i class="material-icons">close</i>
  </div>
  <h2>{{ (isNewItem ? 'admin-page.support-contact.add.title-add' : 'admin-page.support-contact.add.title-edit') | translate }}</h2>
  <div class="edit-container">
    <div class="main-tile">
      <mat-form-field appearance="fill" *ngIf="id === 'contact|text'">
        <mat-label>{{ 'admin-page.support-contact.add.title-text' | translate }} </mat-label>
        <input type="text" matInput [(ngModel)]="title" />
        <mat-error *ngIf="!title || title.trim().length === 0">
          {{ 'admin-page.warning' | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" *ngIf="id !== 'contact|text'">
        <mat-label>{{ 'admin-page.support-contact.add.organization-text' | translate }}</mat-label>
        <mat-label *ngIf="id === 'contact|text'">{{ 'admin-page.support-contact.add.title-text' | translate }} </mat-label>
        <input type="text" matInput [(ngModel)]="organization" />
        <mat-error *ngIf="!organization || organization.trim().length === 0">
          {{ 'admin-page.warning' | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="no-margin">
        <mat-label>{{ 'admin-page.support-contact.add.tenant' | translate }}</mat-label>
        <mat-select [(ngModel)]="selectedTenant" required [disabled]="!isNewItem">
          <mat-option *ngFor="let tenant of tenantValues" [value]="tenant">{{ tenant }}</mat-option>
        </mat-select>
        <mat-error *ngIf="!selectedTenant">
          {{ 'admin-page.warning' | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field *ngIf="id !== 'contact|text'" appearance="fill" class="no-margin">
        <mat-label>{{ 'admin-page.support-contact.add.region' | translate }}</mat-label>
        <mat-select [(ngModel)]="selectedRegion" required [disabled]="!isNewItem">
          <mat-option *ngFor="let item of regions" [value]="item.region">{{ item.region }}</mat-option>
        </mat-select>
        <mat-error *ngIf="!selectedRegion">
          {{ 'admin-page.warning' | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field *ngIf="id !== 'contact|text'" appearance="fill">
        <mat-label>{{ 'admin-page.help-topics.add.addOn.select-text' | translate }}</mat-label>
        <mat-select [(ngModel)]="country" required>
          <mat-form-field appearance="fill" style="width: 100%">
            <mat-label>Search country</mat-label>
            <input type="text" matInput [(ngModel)]="searchCountry" (keyup)="onKey()" />
          </mat-form-field>
          <mat-option *ngFor="let country of selectedCountries" [value]="country">{{ country }}</mat-option>
        </mat-select>
        <mat-error *ngIf="!country">
          {{ 'admin-page.warning' | translate }}
        </mat-error>
      </mat-form-field>

      <div *ngIf="id === 'contact|text'" class="text-editor">
        <h4>{{ 'admin-page.support-contact.add.content-text' | translate }}</h4>
        <angular-editor [placeholder]="'admin-page.support-contact.edit.answer-placeholder' | translate" [config]="editorConfig" [(ngModel)]="contact"> </angular-editor>
        <p class="warning" *ngIf="!contact || contact.trim().length === 0">{{ 'admin-page.warning' | translate }}</p>
      </div>

      <mat-form-field *ngIf="id !== 'contact|text'" appearance="fill">
        <mat-label>{{ 'admin-page.support-contact.add.contact-text' | translate }}</mat-label>
        <input type="text" matInput required [(ngModel)]="contact" />
        <mat-error *ngIf="!contact || contact.trim().length === 0">
          {{ 'admin-page.warning' | translate }}
        </mat-error>
      </mat-form-field>
    </div>

    <app-action-button class="save-button" [disabled]="!isSaveEnabled" (click)="createSupportContact()">
      {{ 'general.save' | translate }}
    </app-action-button>
  </div>
</div>
