import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { WhatsNewItem, WhatsNewItemExtended } from '../interfaces/IWhatsNewExtended';
import { environment } from '../../../environments/environment';
import { TENANT } from '../Tenant.enum';

@Injectable()
export class WhatsNewService {
  private allTitles?: WhatsNewItem[];
  constructor(public http: HttpClient) {}

  getAllWhatsNew(company: string): Observable<WhatsNewItem[]> {
    if (this.allTitles) {
      return of(this.allTitles);
    }

    const URL = `${environment.api.backend}whats-new?tenant=${company}`;
    return this.http.get<WhatsNewItem[]>(URL).pipe(tap(titles => (this.allTitles = titles)));
  }

  getAllForCMS(): Promise<WhatsNewItemExtended[]> {
    const URL = `${environment.api.backend}whats-new/cms`;
    return this.http.get<WhatsNewItemExtended[]>(URL).toPromise();
  }

  saveNewWhatsNew(tenant: TENANT, title: WhatsNewItem): Promise<any> {
    const URL = `${environment.api.backend}whats-new?tenant=${tenant}&id=${title.id}&language=en`;
    return this.http.post(URL, title).toPromise();
  }

  updateWhatsNew(tenant: TENANT, item: WhatsNewItem): Promise<any> {
    const URL = `${environment.api.backend}whats-new?tenant=${tenant}&id=${item.id}&title=${item.title}&content=${item.content}&language=en`;
    return this.http.put(URL, item).toPromise();
  }
}
