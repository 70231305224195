import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { Md5 } from 'ts-md5';
import { TrackingSuiteService, TrackingRecord } from '@siemens/tracking-suite-angular-lib';
import { Subscription, tap } from 'rxjs';
import { AuthUser } from 'src/app/auth/models/auth-user.model';
import { AuthService } from '../../auth/services/auth.service';
import { UserOperations } from '../operations/user.operations';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {
  protected visitToken: string; // hashed from accessToken
  protected engTranslations;
  private user: AuthUser;
  private guid: string; // hashed from GID
  private rawAccessToken: string;
  private subs: Subscription[] = [];

  constructor(
    private translate: TranslateService,
    private userService: UserService,
    private readonly trackingSuiteService: TrackingSuiteService,
    private authService: AuthService
  ) {
    this.getUser().subscribe();
    this.loadEnglishTranslations();
  }

  public addWCSTrackingItem(itemType: 'route' | 'action' | 'language' | 'help-item' | 'helpItem-link', item: string): void {
    if (!this.user) {
      this.getUser()
        .pipe(
          tap(() => {
            this.trackItem(itemType, item);
          })
        )
        .subscribe();
    } else {
      this.trackItem(itemType, item);
    }
  }

  private getUser() {
    return this.authService.getAuthInfo().pipe(
      tap(({ accessToken, idToken }) => {
        this.user = UserOperations.idTokenToUserData(idToken);
        this.guid = Md5.hashStr(this.user.gid);
        this.visitToken = Md5.hashStr(accessToken);
        this.rawAccessToken = accessToken;
      })
    );
  }

  private trackItem(itemType: 'route' | 'action' | 'language' | 'help-item' | 'helpItem-link', item: string): void {
    const newTrackingRecord = this.setUpNewRecordFormat(itemType, item);
    this.useNewTrackingService(newTrackingRecord);
  }

  private setUpNewRecordFormat(itemType: 'route' | 'action' | 'language' | 'help-item' | 'helpItem-link', item: string): TrackingRecord {
    let action: 'click' | 'navigate' = 'click';
    if (itemType === 'route') {
      action = 'navigate';
    } else {
      item = itemType + ' - ' + item;
    }
    return {
      accessToken: this.rawAccessToken,
      userGid: this.guid,
      actionType: action,
      actionValue: item,
      orgCode: this.user && this.user.orgCode ? this.user.orgCode : 'unset',
      language: this.translate.currentLang ?? 'en',
      userCountry: this.user && this.user.country ? this.user.country : 'unset'
    };
  }

  private useNewTrackingService(record: TrackingRecord): void {
    this.trackingSuiteService.send(record).subscribe();
  }

  private loadEnglishTranslations(): void {
    this.subs.push(
      this.translate.getTranslation('en').subscribe(value => {
        if (value) {
          this.engTranslations = value;
        }
      })
    );
  }
}
