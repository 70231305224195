import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ISupportContactItem, ISupportContactExtended } from '../interfaces/ISupportContact';
import { IRegionItem } from '../interfaces/IRegion';
import { environment } from '../../../environments/environment';
import { TENANT } from '../Tenant.enum';

@Injectable()
export class SupportContactsService {
  private allTitles?: ISupportContactItem[];
  constructor(public http: HttpClient) {}

  getAllSupportContacts(company: string): Observable<ISupportContactItem[]> {
    if (this.allTitles) {
      return of(this.allTitles);
    }

    const URL = `${environment.api.backend}support-contacts?tenant=${company}`;
    return this.http.get<ISupportContactItem[]>(URL).pipe(tap(titles => (this.allTitles = titles)));
  }

  getAllRegions(): Promise<IRegionItem[]> {
    const URL = `${environment.api.backend}regions`;
    return this.http.get<IRegionItem[]>(URL).toPromise();
  }

  getAllForCMS(): Promise<ISupportContactExtended[]> {
    const URL = `${environment.api.backend}support-contacts/cms`;
    return this.http.get<ISupportContactExtended[]>(URL).toPromise();
  }

  saveNewSupportContact(tenant: TENANT, item: ISupportContactItem): Promise<any> {
    const URL = `${environment.api.backend}support-contacts?tenant=${tenant}&id=${item.id}&language=en`;
    return this.http.post(URL, item).toPromise();
  }

  saveNewRegion(item: IRegionItem): Promise<any> {
    const URL = `${environment.api.backend}regions?tenant=siemens&region=${item.region}&id=${item.id}&language=en`;
    return this.http.post(URL, item).toPromise();
  }

  updateSupportContact(tenant: TENANT, item: ISupportContactItem): Promise<any> {
    const URL = `${environment.api.backend}support-contacts?tenant=${tenant}&id=${item.id}&language=en`;
    return this.http.put(URL, item).toPromise();
  }
}
