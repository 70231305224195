import { CountryEnum } from './../enums/CountryEnum';

export class ISupportContactItem {
  id: string;
  title?: string;
  region?: string;
  country?: CountryEnum;
  organization?: string;
  contact?: string;
}

export interface ISupportContactExtended {
  siemens: ISupportContactItem;
  shs: ISupportContactItem;
  siemensenergy: ISupportContactItem;
  country?: string;
}
