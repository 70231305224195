import { IColors } from '../interfaces/IColor';
import { IAssets } from '../interfaces/IAssets';

export const COLORS_SAG: IColors = {
  primary50: '#f4f3f2',
  primary200: '#62eec7',
  primary: '#41aaaa',
  primary900: '#00646e',
  headerText: '#000028',
  textColor: '#000028',
  mostViewedLight: '#ccccd4',
  mostViewedDark: '#333353',
  warnButton: '#f17a5a',
  warnButtonHover: '#f59a7a',
  warnButtonLight: '#ffeeea',
  notificationsBG: '#00E2BB'
};

export const COLORS_SE: IColors = {
  ...COLORS_SAG,
  primary50: '#f4f3f2',
  primary200: '#966ab1',
  primary: '#641e8C',
  primary900: '#1b1534',
  textColor: '#000028',
  mostViewedLight: '#ccccd4',
  mostViewedDark: '#641e8c',
  notificationsBG: '#aa32be'
};

export const COLORS_SHS: IColors = {
  ...COLORS_SAG,
  primary50: '#f4f3f2',
  primary200: '#f09155',
  primary: '#ec6602',
  primary900: '#ab4606',
  textColor: '#000028',
  mostViewedLight: '#ccccd4',
  mostViewedDark: '#d85808',
  notificationsBG: '#ff9000'
};

export const ASSETS_SAG: IAssets = {
  cloudIcon: './assets/svgs/cloud-icon.svg',
  headerLogo: './assets/svgs/logo-siemens.svg'
};

export const ASSETS_SE: IAssets = {
  ...ASSETS_SAG,
  cloudIcon: './assets/svgs/cloud-icon-se.svg',
  headerLogo: './assets/svgs/logo-energy.svg'
};

export const ASSETS_SHS: IAssets = {
  ...ASSETS_SAG,
  cloudIcon: './assets/svgs/cloud-icon-shs.svg',
  headerLogo: './assets/svgs/logo-healthineers.svg'
};

// export const siemensTranslations = {
//   equityDisclaimerWarningText: 'equity.disclaimer.warning-text',
//   equityDisclaimerTermsConditionsText: 'equity.disclaimer.terms-and-conditions-text',
//   equityDisclaimerConfirmationText: 'equity.disclaimer.confirmation-text'
// }

// export const healthineersTranslations = {
//   equityDisclaimerWarningText: 'shs.equity.disclaimer.warning-text',
//   equityDisclaimerTermsConditionsText: 'shs.equity.disclaimer.terms-and-conditions-text',
//   equityDisclaimerConfirmationText: 'shs.equity.disclaimer.confirmation-text'
// }

// export const sagVideos = {
//   userMenu: 'assets/videos/first-user-experience/sag-user-menu.mov',
//   timeline: 'assets/videos/first-user-experience/sag-timeline.mov',
//   task: 'assets/videos/first-user-experience/sag-task.mov',
//   sideMenu: 'assets/videos/first-user-experience/sag-side-menu.mov',
//   faq: 'assets/videos/first-user-experience/sag-faq.mov',
//   equity: 'assets/videos/first-user-experience/sag-equity.mov',
//   equateId: 'assets/videos/first-user-experience/sag-equateId.mov'
// }

// export const shsVideos = {
//   userMenu: 'assets/videos/first-user-experience/shs-user-menu.mov',
//   timeline: 'assets/videos/first-user-experience/shs-timeline.mov',
//   task: 'assets/videos/first-user-experience/shs-task.mov',
//   sideMenu: 'assets/videos/first-user-experience/shs-side-menu.mov',
//   faq: 'assets/videos/first-user-experience/shs-faq.mov',
//   equity: 'assets/videos/first-user-experience/shs-equity.mov',
//   equateId: 'assets/videos/first-user-experience/shs-equateId.mov'
// }

// export const siemensDesignSet = {

//   borderRadius: '4px',
//   cardElevationFlat: 'none',
//   carElevationRaised: '0px 0px 2px rgba(0, 0, 40, 0.1), 0px 4px 8px rgba(0, 0, 40, 0.1), 0px 12px 18px rgba(0, 0, 40, 0.1)',
//   activeBackground: '#C2FFEE',
//   textOnActiveBackground: '#005159',
//   textColor: '#000028',
//   dialogHeaderColor: '#ffffff',
//   dialogHeaderText: '#000028',

//     // Purple – Primary
//      purple1000: '#000429',
//      purple1000Rgb: '0, 4, 41',
//      purple900: '#001538',
//      purple900Rgb: '0, 21, 56',
//      purple800: '#002B48',
//      purple800Rgb: '0, 43, 72',
//      purple700: '#004057',
//      purple700Rgb: '0, 64, 87',
//      purple600: '#66667E', //changed
//      purple600Rgb: '3, 73, 94',
//      purple500: '#00596A',
//      purple500Rgb: '0, 89, 106',
//      purple400: '#026171',
//      purple400Rgb: '2, 97, 113',
//      purple300: '#00767F',
//      purple300Rgb: '0, 118, 127',
//      purple200: '#00898C',
//      purple200Rgb: '0, 137, 140',
//      purple100: '#029899',
//      purple100Rgb: '2, 152, 153',
//      purple50: '#C9BBD8',
//      purple50Rgb: '201, 187, 216',

//     // Petrol – Secondary
//      darkpetrol: '#2d373c',
//      petrol900: '#009999',
//      petrol800: '#17A2A2',
//      petrol700: '#2EACAC',
//      petrol600: '#46B5B5',
//      petrol500: '#5DBEBE',
//      petrol400: '#74C7C7',
//      petrol300: '#8BD1D1',
//      petrol200: '#A3D9D9',
//      petrol100: '#BBE2E2',
//      petrol50: '#CCCCD4',

//     // Dark Grey
//      darkgrey900: '#000028', //changed
//      darkgrey900Rgb: '27, 21, 52',
//      darkgrey800: '#62EEC7', //changed
//      darkgrey800Rgb: '54, 49, 76',
//      darkgrey700: '#514C66',
//      darkgrey700Rgb: '81, 76, 102',
//      darkgrey600: '#4C4C68', //changed
//      darkgrey600Rgb: '107, 102, 128',
//      darkgrey500: '#878299',
//      darkgrey500Rgb: '135, 130, 153',
//      darkgrey400: '#000028', //changed
//      darkgrey400Rgb: '163, 159, 176',
//      darkgrey300: '#BDBCC8',
//      darkgrey300Rgb: '189, 188, 200',

//     // Light Grey
//      lightgrey600: '#A9B9D1',
//      lightgrey500: '#B5C4D9',
//      lightgrey400: '#C3D0E2',
//      lightgrey300: '#D0DAE9',
//      lightgrey200: '#F3F3F0',
//      lightgrey100: '#EBEEF5',
//      lightgrey50: '#F8F8FC',

//     // Extra Colors
//      darkBlue: '#000028',
//      greyBlue: '#507486',
//      blue600: '#0066CC',
//      blue600Rgb: '0, 102, 204',
//      blue500: '#408CD9',
//      blue500Rgb: '64, 140, 217',
//      blue400: '#7EB3E8',
//      blue400Rgb: '126, 179, 232',
//      orange600: '#FF8C1A',
//      orange600Rgb: '255, 140, 26',
//      orange500: '#FFAA33',
//      orange500Rgb: '255, 170, 51',
//      orange400: '#FFC470',
//      orange400Rgb: '255, 196, 112',
//      red600: '#B81430',
//      red600Rgb: '184, 20, 48',
//      red500: '#E51A3C',
//      red500Rgb: '229, 26, 60',
//      red400: '#ED5E76',
//      red400Rgb: '237, 94, 118',
//      green600: '#29A347',
//      green600Rgb: '41, 163, 71',
//      green500: '#2FBC52',
//      green500Rgb: '47, 188, 82',
//      green400: '#69D377',
//      green400Rgb: '105, 211, 119',
// }

// export const healthineersDesignSet = {

//   borderRadius: '0px',
//   cardElevationFlat: '0 0 0 1px rgba(0,0,0,0.10)',
//   carElevationRaised: '0 2px 4px 0 rgba(0,0,0,0.10), 0 -1px 2px 0 rgba(0,0,0,0.05)',
//   activeBackground: '#cf4b00',
//   textOnActiveBackground: '#ffffff',
//   textColor: '#000000',
//   dialogHeaderColor: '#000000',
//   dialogHeaderText: '#ffffff',

//   // Purple – Primary
//    purple1000: '#000429',
//    purple1000Rgb: '0, 4, 41',
//    purple900: '#1a1a1a',
//    purple900Rgb: '0, 21, 56',
//    purple800: '#002b48',
//    purple800Rgb: '0, 43, 72',
//    purple700: '#cf4b00',
//    purple700Rgb: '0, 64, 87',
//    purple600: '#ffffff', //changed
//    purple600Rgb: '3, 73, 94',
//    purple500: '#cf4b00',
//    purple500Rgb: '0, 89, 106',
//    purple400: '#026171',
//    purple400Rgb: '2, 97, 113',
//    purple300: '#00767F',
//    purple300Rgb: '0, 118, 127',
//    purple200: '#00898C',
//    purple200Rgb: '0, 137, 140',
//    purple100: '#029899',
//    purple100Rgb: '2, 152, 153',
//    purple50: '#C9BBD8',
//    purple50Rgb: '201, 187, 216',

//   // Petrol – Secondary
//    darkpetrol: '#2d373c',
//    petrol900: '#009999',
//    petrol800: '#17A2A2',
//    petrol700: '#2EACAC',
//    petrol600: '#46B5B5',
//    petrol500: '#5DBEBE',
//    petrol400: '#74C7C7',
//    petrol300: '#8BD1D1',
//    petrol200: '#A3D9D9',
//    petrol100: '#BBE2E2',
//    petrol50: '#ededed',

//   // Dark Grey
//    darkgrey900: '#1a1a1a',
//    darkgrey900Rgb: '207, 75, 0',
//    darkgrey800: '#ec6602',
//    darkgrey800Rgb: '207, 75, 0',
//    darkgrey700: '#514C66',
//    darkgrey700Rgb: '81, 76, 102',
//    darkgrey600: '#6B6680',
//    darkgrey600Rgb: '107, 102, 128',
//    darkgrey500: '#878299',
//    darkgrey500Rgb: '135, 130, 153',
//    darkgrey400: '#A39FB0',
//    darkgrey400Rgb: '163, 159, 176',
//    darkgrey300: '#BDBCC8',
//    darkgrey300Rgb: '189, 188, 200',

//   // Light Grey
//    lightgrey600: '#A9B9D1',
//    lightgrey500: '#B5C4D9',
//    lightgrey400: '#cccccc',
//    lightgrey300: '#D0DAE9',
//    lightgrey200: '#ededed',
//    lightgrey100: '#EBEEF5',
//    lightgrey50: '#F8F8FC',

//   // Extra Colors
//    darkBlue: '#000028',
//    greyBlue: '#507486',
//    blue600: '#0066CC',
//    blue600Rgb: '0, 102, 204',
//    blue500: '#408CD9',
//    blue500Rgb: '64, 140, 217',
//    blue400: '#7EB3E8',
//    blue400Rgb: '126, 179, 232',
//    orange600: '#FF8C1A',
//    orange600Rgb: '255, 140, 26',
//    orange500: '#FFAA33',
//    orange500Rgb: '255, 170, 51',
//    orange400: '#FFC470',
//    orange400Rgb: '255, 196, 112',
//    red600: '#B81430',
//    red600Rgb: '184, 20, 48',
//    red500: '#E51A3C',
//    red500Rgb: '229, 26, 60',
//    red400: '#ED5E76',
//    red400Rgb: '237, 94, 118',
//    green600: '#29A347',
//    green600Rgb: '41, 163, 71',
//    green500: '#2FBC52',
//    green500Rgb: '47, 188, 82',
//    green400: '#69D377',
//    green400Rgb: '105, 211, 119',
// }
