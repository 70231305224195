import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { TENANT } from 'src/app/shared/Tenant.enum';
import { WhatsNewService } from 'src/app/shared/services/whats-new.service';
import { IWhatsNewLink } from 'src/app/shared/interfaces/IWhatsNewLink';
import { TenantService } from 'src/app/shared/services/tenant.service';
import { WhatsNewItem } from './../../../shared/interfaces/IWhatsNewExtended';
@Component({
  selector: 'app-add-whats-new',
  templateUrl: './add-whats-new.component.html',
  styleUrls: ['./add-whats-new.component.scss']
})
export class AddWhatsNewComponent implements OnInit {
  TENANT = TENANT;
  tenantValues = Object.values(TENANT);
  selectedTenant: TENANT;
  whatsNewItem: WhatsNewItem;
  isNewItem = true;
  editorConfig: AngularEditorConfig = {
    minHeight: '4rem',
    editable: true,
    sanitize: false,
    toolbarHiddenButtons: [
      ['strikeThrough', 'justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'heading', 'fontName', 'subscript', 'superscript'],
      ['fontSize', 'textColor', 'backgroundColor', 'customClasses', 'insertHorizontalRule', 'removeFormat', 'toggleEditorMode', 'insertImage', 'insertVideo']
    ]
  };
  get isSaveEnabled(): boolean {
    return true;
  }

  constructor(
    private tenantService: TenantService,
    public dialog: MatDialog,
    private toast: ToastrService,
    private whatsNewService: WhatsNewService,
    public dialogRef: MatDialogRef<AddWhatsNewComponent>,
    @Inject(MAT_DIALOG_DATA) public data?: { whatsNewItem: WhatsNewItem; tenant: TENANT }
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.isNewItem = false;
      this.whatsNewItem = this.data.whatsNewItem;
      this.selectedTenant = this.data.tenant;
    }
  }

  closeNewFaqDialogue(): void {
    this.dialog.closeAll();
  }

  addLink(): void {
    this.data.whatsNewItem.links.push(new IWhatsNewLink());
  }

  deleteLink(index: number): void {
    this.data.whatsNewItem.links.splice(index, 1);
  }

  async createWhatsNew(): Promise<void> {
    const questionItem = new WhatsNewItem();
    questionItem.title = this.whatsNewItem.title;
    questionItem.content = this.whatsNewItem.content;
    questionItem.id = this.whatsNewItem.id;
    questionItem.links = this.whatsNewItem.links;

    await this.saveWhatsNewToDBs(questionItem);
  }

  async saveWhatsNewToDBs(item: WhatsNewItem): Promise<void> {
    // fixes a bug which deletes the text after inserting and saving url link
    item.content = item.content ? this.replaceAll(item.content, '&nbsp;', ' ') : item.content;

    try {
      if (this.isNewItem) {
        await this.whatsNewService.saveNewWhatsNew(this.selectedTenant, item);
        this.toast.success('Added item to database.');
      } else {
        await this.whatsNewService.updateWhatsNew(this.data.tenant, item);
        this.toast.success('Successfully edited item.');
      }
      this.dialog.closeAll();
      const url = `${this.tenantService.getBaseUrlByTenant(this.selectedTenant)}/admin/whats-new?id=${item.title.replace(/[^a-z0-9]/gi, '')}`;
      window.location.href = url;
    } catch (error) {
      console.log(error);
      this.toast.error('An error occurred...');
    }
  }

  escapeRegExp(string: string): string {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  // We don't need to declare this functions replaceAll and escapeRegExp if
  // we change the target library - by changing the 'lib' compiler option to 'es2021' or later.
  replaceAll(str: string, find: string, replace: string): string {
    return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
  }
}
