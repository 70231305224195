import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { filter } from 'rxjs/operators';
import { UserState } from '../auth/store';
import { UserRoleEnum } from '../shared/enums/UserRoleEnum';
import { TenantService } from '../shared/services/tenant.service';
import { AdminTopicsTileInterface } from './interfaces/AdminTopicsTile.interface';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  userRole?: UserRoleEnum;
  UserRoleEnum = UserRoleEnum;

  ngOnInit(): void {
    this.store
      .select(UserState.role)
      .pipe(filter(role => role !== undefined))
      .subscribe(role => (this.userRole = role));
  }

  constructor(private router: Router, private store: Store, public tenantService: TenantService) {}

  tiles: AdminTopicsTileInterface[] = [
    // {
    //   title: 'Translations',
    //   matIcon: 'translate',
    //   position: 'bottom-right',
    //   url: '/admin/translations'
    // },
    {
      title: 'User Management',
      matIcon: 'manage_accounts',
      position: 'bottom-right',
      url: '/admin/user-management'
    }
  ];

  cmsTiles: AdminTopicsTileInterface[] = [
    // {
    //   title: 'Critical news',
    //   matIcon: 'newspaper',
    //   position: 'bottom-right',
    //   url: '/admin/critical-news'
    // },
    {
      title: 'What\'s new section',
      matIcon: 'home',
      position: 'bottom-right',
      url: '/admin/whats-new'
    },
    {
      title: 'Help Topics',
      matIcon: 'help',
      position: 'bottom-right',
      url: '/admin/help-topics'
    },
    {
      title: 'FAQ',
      matIcon: 'question_answer',
      position: 'bottom-right',
      url: '/admin/faqs'
    },
    {
      title: 'Support contacts',
      matIcon: 'contact_support',
      position: 'bottom-right',
      url: '/admin/support-contacts'
    }
  ];

  openExternalLink(): void {
    window.location.href = 'https://analytics.wcs-apps.siemens.cloud/';
  }
}
