import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tenant-independent',
  templateUrl: './tenant-independent.component.html',
  styleUrls: ['./tenant-independent.component.scss']
})
export class TenantIndependentComponent {
  goTo(tenant: 'siemens' | 'healthineers' | 'energy'): void {
    if (environment.stage === 'prod') {
      switch (tenant) {
        case 'siemens':
          window.open('https://workday-help.siemens.cloud/', '_self');
          break;
        case 'healthineers':
          window.open('https://healthineers.workday-help.siemens.cloud/', '_self');
          break;
        default:
          window.open('https://energy.workday-help.siemens.cloud/', '_self');
          break;
      }
    } else {
      switch (tenant) {
        case 'siemens':
          window.open('https://dev.workday-help.siemens.cloud/', '_self');
          break;
        case 'healthineers':
          window.open('https://dev.healthineers.workday-help.siemens.cloud/', '_self');
          break;
        default:
          window.open('https://dev.energy.workday-help.siemens.cloud/', '_self');
          break;
      }
    }
  }
}
