<div *ngIf="item" (click)="addTracking(item)">
  <div class="container">
    <app-accordion [title]="item.title" design="most-relevant">
      <app-action-button class="share-button" (click)="copyShareLink()" [design]="showJustShared ? 'stroke' : 'filled'">
        <span *ngIf="!showJustShared"><mat-icon>content_copy</mat-icon> {{ 'help-items.share' | translate }}</span>
        <span *ngIf="showJustShared"><mat-icon>checkmark</mat-icon> {{ 'help-items.copied' | translate }}</span>
      </app-action-button>

      <div *ngIf="item.description || item.description === ''">
        <div [innerHTML]="item.description"></div>
      </div>
      <div class="detail-container">
        <div class="process-items">
          <!-- Videos -->
          <ng-container *ngIf="item.videos.length > 0">
            <div *ngFor="let video of item.videos">
              <h4>{{ video.title }}</h4>
              <div class="video-container">
                <app-videoplayer [videolink]="video.url" [autoplay]="false"></app-videoplayer>
                <div *ngIf="video.label" class="video-label">{{ video.label }}</div>
              </div>
            </div>
          </ng-container>
          <!-- Links -->
          <ng-container *ngIf="item.links.length > 0">
            <h4>{{ 'admin-page.help-topics.edit.links-title' | translate }}</h4>
            <div *ngFor="let link of item.links" class="link-items" (click)="addTrackingLink(link)">
              <a href="{{ link.linkUrl }}">{{ link.label }}</a>
            </div>
          </ng-container>
          <!-- Add Ons -->
          <ng-container *ngIf="item.addOns.length > 0">
            <h4>{{ 'admin-page.help-topics.edit.addOn-title' | translate }}</h4>
            <div *ngFor="let addOn of item.addOns">
              <app-country-add-on [countryName]="addOn.label" [countryCode]="addOn.country" [addOnUrls]="addOn.addOnUrls" [collapsed]="item.addOns.length > 1">
              </app-country-add-on>
            </div>
          </ng-container>
        </div>
      </div>
    </app-accordion>
  </div>
</div>
