<div class="faq-list-actions">
  <button *ngIf="(userRole$ | async) === userRoleEnum.ADMIN" class="add-faq-item" mat-raised-button [routerLink]="'/admin/faqs'">
    {{ 'admin-page.faq.buttons.go-to-admin-faq' | translate }}
  </button>
</div>
<!-- SEARCH -->
<app-faq-search
  [showTextSearch]="showTextSearch"
  [showCategorySearch]="showCategorySearch"
  [categoryTiles]="tiles"
  [amountOfSearchHits]="searchResult?.length"
  (categoryClicked)="handleCategoryClicked($event.category, $event.categoryIndex)"
  (searchInput)="startSearch($event)"
  (searchCleared)="clearSearch()"
></app-faq-search>
<!-- FAQS -->
<ng-container *ngIf="defaultCategoryIfLimit && questionLimitIfNoSearch && !searchResult?.length && !searchValue && !selectedCategories.length; else noLimitedSearch">
  <h2>
    {{ defaultCategoryIfLimit }}
  </h2>
  <app-question *ngFor="let item of getQuestionsByCategory(defaultCategoryIfLimit) | slice : 0 : questionLimitIfNoSearch" [question]="item"> </app-question>
</ng-container>
<ng-template #noLimitedSearch>
  <div class="category-container" *ngFor="let category of keys">
    <div *ngIf="categories[category]">
      <h2>
        {{ category }}
      </h2>
      <div class="faq-tiles">
        <app-question *ngFor="let item of getQuestionsByCategory(category)" [question]="item"> </app-question>
      </div>
    </div>
  </div>
</ng-template>
