<div class="container">
  <div class="header">
    <mat-icon (click)="onClickBack()">{{ 'arrow_back' }}</mat-icon>
    <h2>{{ categoryName }}</h2>
  </div>
  <div class="icons">
    <app-most-relevant-item *ngFor="let item of items" [item]="item" [attr.data-id]="item.id"> </app-most-relevant-item>
  </div>
  <div class="faqs">
    <app-faq id="faq" [category]="categoryName"></app-faq>
  </div>
</div>
