import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { CategoryEnum } from 'src/app/shared/interfaces/ICategory';
import { IQuestion } from 'src/app/shared/interfaces/IQuestion';
import { TenantService } from 'src/app/shared/services/tenant.service';
import { v4 as uuid } from 'uuid';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { TENANT } from 'src/app/shared/Tenant.enum';
import { FaqService } from 'src/app/shared/services/faq.service';

@Component({
  selector: 'app-add-faq',
  templateUrl: './add-faq.component.html',
  styleUrls: ['./add-faq.component.scss']
})
export class AddFaqComponent implements OnInit {
  company = this.tenantService.getTenantString();
  questionText: string;
  answer: string;
  selectedCategory: CategoryEnum;

  TENANT = TENANT;
  tenantValues = Object.values(TENANT);
  selectedTenant: TENANT;
  isNewQuestion = true;
  editorConfig: AngularEditorConfig = {
    minHeight: '4rem',
    editable: true,
    sanitize: true,
    toolbarHiddenButtons: [
      ['strikeThrough', 'justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'heading', 'fontName', 'subscript', 'superscript'],
      ['fontSize', 'textColor', 'backgroundColor', 'customClasses', 'insertHorizontalRule', 'removeFormat', 'toggleEditorMode', 'insertImage', 'insertVideo']
    ]
  };
  get isSaveEnabled(): boolean {
    const questionValid = this.questionText !== undefined && this.questionText.trim().length > 0;
    const answerValid = this.answer !== undefined && this.answer.trim().length > 0;
    return questionValid && answerValid && this.selectedCategory !== undefined;
  }

  constructor(
    public dialog: MatDialog,
    private http: HttpClient,
    private toast: ToastrService,
    private tenantService: TenantService,
    private faqService: FaqService,
    public dialogRef: MatDialogRef<AddFaqComponent>,
    @Inject(MAT_DIALOG_DATA) public data?: { question: IQuestion; tenant: TENANT }
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.isNewQuestion = false;

      this.questionText = this.data.question.question;
      this.answer = this.data.question.answer;
      this.selectedCategory = this.data.question.category;
      this.selectedTenant = this.data.tenant;
    }
  }

  closeNewFaqDialogue(): void {
    this.dialog.closeAll();
  }

  async createFaq(question: string, answer: string, category: CategoryEnum): Promise<void> {
    const questionItem = new IQuestion();
    questionItem.question = question;
    questionItem.answer = answer;
    questionItem.id = this.isNewQuestion ? `${uuid()}` : this.data.question.id;
    questionItem.category = category;

    await this.saveFaqToDBs(questionItem);
  }

  async saveFaqToDBs(question: IQuestion): Promise<void> {
    try {
      if (this.isNewQuestion) {
        await this.faqService.saveNewFAQ(this.selectedTenant, question);
        this.toast.success('Added item to database.');
      } else {
        await this.faqService.updateFAQ(this.data.tenant, question);
        this.toast.success('Successfully edited item.');
      }
      this.dialog.closeAll();
      window.location.reload();
    } catch (error) {
      console.log(error);
      this.toast.error('An error occurred...');
    }
  }
}
