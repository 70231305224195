<div class="add-help-topics-dialogue" mat-dialog-content>
  <div class="close-dialogue-icon" mat-icon (click)="closeAddHelpTopicDialogue()">
    <i class="material-icons">close</i>
  </div>
  <h2>{{ (isNewHelpItem ? 'admin-page.help-topics.add.title' : 'admin-page.help-topics.add.title-edit') | translate }}</h2>
  <div class="edit-container">
    <mat-form-field appearance="fill">
      <mat-label>{{ 'admin-page.help-topics.add.item-title' | translate }}</mat-label>
      <input type="text" matInput required [(ngModel)]="helpItem.title" />
      <mat-error *ngIf="!helpItem.title || helpItem.title.trim().length === 0">
        {{ 'admin-page.warning' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" class="no-margin">
      <mat-label>{{ 'admin-page.help-topics.add.tenant' | translate }}</mat-label>
      <mat-select [(ngModel)]="helpItem.tenant" required [disabled]="!isNewHelpItem">
        <mat-option *ngFor="let tenant of tenantValues" [value]="tenant">{{ tenant }}</mat-option>
      </mat-select>
      <mat-error *ngIf="!helpItem.tenant">
        {{ 'admin-page.warning' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" class="no-margin">
      <mat-label>{{ 'admin-page.help-topics.add.category.title' | translate }}</mat-label>
      <mat-select [(ngModel)]="helpItem.category" required>
        <mat-option *ngFor="let cat of categories" [value]="cat">{{ cat }}</mat-option>
      </mat-select>
      <mat-error *ngIf="!helpItem.category">
        {{ 'admin-page.warning' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>{{ 'admin-page.help-topics.add.sort-order' | translate }}</mat-label>
      <input type="number" matInput required [(ngModel)]="helpItem.sortOrder" />
      <mat-error *ngIf="!helpItem.sortOrder">
        {{ 'admin-page.warning' | translate }}
      </mat-error>
    </mat-form-field>

    <div class="text-editor">
      <h4>{{ 'admin-page.whats-new.add.content-text' | translate }}</h4>
      <angular-editor [placeholder]="'admin-page.faq.edit.answer-placeholder' | translate" [config]="editorConfig" [(ngModel)]="helpItem.description"> </angular-editor>
    </div>

    <mat-slide-toggle [(ngModel)]="helpItem.isMostRelevant">{{ 'admin-page.help-topics.add.most-relevant' | translate }}</mat-slide-toggle>

    <mat-form-field *ngIf="helpItem.isMostRelevant" appearance="fill">
      <mat-label>{{ 'admin-page.help-topics.add.sort-order-most-relevant' | translate }}</mat-label>
      <input type="number" matInput required [(ngModel)]="helpItem.sortOrderMostRelevant" />
      <mat-error *ngIf="!helpItem.sortOrderMostRelevant">
        {{ 'admin-page.warning' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ 'admin-page.help-topics.add.new-video-text' | translate }}
          </mat-panel-title>
          <mat-panel-description class="warning" *ngIf="!isVideoInputValid">{{ 'admin-page.missing-data' | translate }}</mat-panel-description>
        </mat-expansion-panel-header>

        <div class="item-list-container">
          <div *ngFor="let video of helpItem.videos; let idx = index" class="item-container">
            <app-file-uploader
              class="file-uploader"
              *ngIf="!videoUploadStatus[idx].uploadComplete"
              textTranslation="admin-page.help-topics.add.video.upload-text"
              buttonTranslation="admin-page.help-topics.add.video.browse-file-text"
              accept="*"
              (onFilesChange)="onFilesChange($event, idx, true)"
            >
            </app-file-uploader>

            <div *ngIf="videoUploadStatus[idx].uploadComplete">
              <h3>{{ videoUploadStatus[idx].filename }}</h3>
            </div>

            <div>
              <mat-form-field appearance="fill">
                <mat-label>{{ 'admin-page.help-topics.add.video.video-text' | translate }}</mat-label>
                <input type="text" matInput required [(ngModel)]="video.title" />
                <mat-error *ngIf="!video.title || video.title.trim().length === 0">
                  {{ 'admin-page.warning' | translate }}
                </mat-error>
              </mat-form-field>

              <mat-form-field appearance="fill">
                <mat-label>{{ 'admin-page.help-topics.add.video.label-text' | translate }}</mat-label>
                <input type="text" matInput required [(ngModel)]="video.label" />
                <mat-error *ngIf="!video.title || video.title.trim().length === 0">
                  {{ 'admin-page.warning' | translate }}
                </mat-error>
              </mat-form-field>

              <div class="delete-item-button">
                <app-action-button design="stroke" fill="warn" (click)="deleteVideo(idx)">Delete item </app-action-button>
              </div>
            </div>
          </div>
        </div>

        <button class="fab-add-button" mat-fab aria-label="Add new Video" (click)="addVideo()">
          <mat-icon>add</mat-icon>
        </button>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ 'admin-page.help-topics.add.new-link-text' | translate }}
          </mat-panel-title>
          <mat-panel-description class="warning" *ngIf="!isLinkInputValid">{{ 'admin-page.missing-data' | translate }}</mat-panel-description>
        </mat-expansion-panel-header>

        <div class="item-list-container">
          <div *ngFor="let link of helpItem.links; let idx = index" class="item-container">
            <app-file-uploader
              class="file-uploader"
              *ngIf="!linkUploadStatus[idx].uploadComplete || !(link.linkUrl && link.linkUrl.trim().length > 0)"
              textTranslation="admin-page.help-topics.add.links.upload-text"
              buttonTranslation="admin-page.help-topics.add.links.browse-file-text"
              accept="*"
              (onFilesChange)="onFilesChange($event, idx, false)"
            >
            </app-file-uploader>

            <div *ngIf="linkUploadStatus[idx].uploadComplete && linkUploadStatus[idx].filename">
              <h3>{{ linkUploadStatus[idx].filename }}</h3>
            </div>

            <mat-form-field appearance="fill">
              <mat-label>{{ 'admin-page.help-topics.add.links.label' | translate }}</mat-label>
              <input type="text" matInput required [(ngModel)]="link.label" />
              <mat-error *ngIf="!link.label || link.label.trim().length === 0">
                {{ 'admin-page.warning' | translate }}
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>{{ 'admin-page.help-topics.add.links.link-url' | translate }}</mat-label>
              <input type="text" matInput required [(ngModel)]="link.linkUrl" [disabled]="linkUploadStatus[idx].uploadComplete" />
              <mat-error *ngIf="!link.linkUrl || link.linkUrl.trim().length === 0">
                {{ 'admin-page.warning' | translate }}
              </mat-error>
            </mat-form-field>
            <div class="action-buttons">
              <app-action-button design="stroke" fill="primary" (click)="updateLink(idx)">
                <mat-icon class="button-icon">edit</mat-icon><span>{{ 'admin-page.help-topics.edit.buttons.edit' | translate }}</span>
              </app-action-button>
              <app-action-button design="stroke" fill="warn" (click)="deleteLink(idx)">
                <mat-icon class="button-icon">delete</mat-icon><span>{{ 'admin-page.help-topics.edit.buttons.delete' | translate }}</span>
              </app-action-button>
            </div>
          </div>
        </div>

        <button class="fab-add-button" mat-fab color="primary" aria-label="Add new Link" (click)="addLink()">
          <mat-icon>add</mat-icon>
        </button>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ 'admin-page.help-topics.add.new-addOn-text' | translate }}
          </mat-panel-title>
          <mat-panel-description class="warning" *ngIf="!isAddOnInputValid">{{ 'admin-page.missing-data' | translate }}</mat-panel-description>
        </mat-expansion-panel-header>

        <div class="item-list-container">
          <div *ngFor="let addOn of helpItem.addOns; let idx = index" class="item-container">
            <mat-form-field appearance="fill">
              <mat-label>{{ 'admin-page.help-topics.add.addOn.select-text' | translate }}</mat-label>
              <mat-select [(ngModel)]="addOn.country" required>
                <mat-option *ngFor="let key of helpItemCountriesKeys" value="{{ key.toLowerCase() }}">
                  {{ HelpItemCountryEnum[key] }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="!addOn.country">
                {{ 'admin-page.warning' | translate }}
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>{{ 'admin-page.help-topics.add.addOn.link-label' | translate }}</mat-label>
              <input type="text" matInput required [(ngModel)]="addOn.label" />
              <mat-error *ngIf="!addOn.label || addOn.label.trim().length === 0">
                {{ 'admin-page.warning' | translate }}
              </mat-error>
            </mat-form-field>

            <div *ngFor="let itemUrl of addOn.addOnUrls; let i = index">
              <mat-form-field appearance="fill">
                <mat-label>{{ 'admin-page.help-topics.add.addOn.link-url' | translate }}</mat-label>
                <input type="text" matInput required [(ngModel)]="itemUrl.addOnUrl" placeholder="https://example.com" />
                <mat-error *ngIf="!itemUrl.addOnUrl || itemUrl.addOnUrl.trim().length === 0">
                  {{ 'admin-page.warning' | translate }}
                </mat-error>
              </mat-form-field>

              <mat-form-field appearance="fill">
                <mat-label>{{ 'admin-page.help-topics.add.addOn.link-text' | translate }}</mat-label>
                <input type="text" matInput [(ngModel)]="itemUrl.linkText" />
              </mat-form-field>

              <mat-form-field appearance="fill">
                <mat-label>{{ 'admin-page.help-topics.add.addOn.link-order-value' | translate }}</mat-label>
                <input type="number" matInput [(ngModel)]="itemUrl.orderValue" />
              </mat-form-field>

              <app-action-button style="padding-bottom: 20px" design="stroke" fill="warn" (click)="deleteCountryAddOnUrl(idx, i)">
                <mat-icon class="button-icon">delete</mat-icon><span>{{ 'admin-page.help-topics.edit.buttons.delete-url' | translate }}</span>
              </app-action-button>
            </div>

            <div class="action-buttons">
              <app-action-button design="stroke" fill="primary" (click)="addCountryAddOnUrl(idx)">
                <mat-icon class="button-icon">add</mat-icon><span>{{ 'admin-page.help-topics.edit.buttons.add-url' | translate }}</span>
              </app-action-button>
              <app-action-button design="stroke" fill="warn" (click)="deleteCountryAddOn(idx)">Delete Country AddOn </app-action-button>
            </div>
          </div>

          <button class="fab-add-button" mat-fab color="primary" aria-label="Add new Country Add-On" (click)="addCountryAddOn()">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <app-action-button class="save-button" [disabled]="!isSaveEnabled" (click)="onSaveClick()">Save </app-action-button>
  </div>
</div>
