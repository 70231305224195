import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { TenantService } from 'src/app/shared/services/tenant.service';
import { MatDialog } from '@angular/material/dialog';
import { TENANT } from 'src/app/shared/Tenant.enum';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { ISupportContactItem, ISupportContactExtended } from '../../../shared/interfaces/ISupportContact';
import { AddSupportContactComponent } from '../add-support-contacts/add-support-contacts.component';
import { IRegionItem } from './../../../shared/interfaces/IRegion';

@Component({
  selector: 'app-edit-support-contacts',
  templateUrl: './edit-support-contacts.component.html',
  styleUrls: ['./edit-support-contacts.component.scss']
})
export class EditSupportContactComponent {
  @Input() set SupportContactExtended(value: ISupportContactExtended) {
    this._supportContactExtended = value;
    this.selectedTenant = this.supportContactExtended[TENANT.Siemens] ? TENANT.Siemens : this.supportContactExtended[TENANT.SHS] ? TENANT.SHS : TENANT.SE;
    this.supportContactItem = value[this.selectedTenant];
    this.selectedRegion = this.supportContactItem.region;
  }

  @Input() set Regions(value: IRegionItem[]) {
    this.regions = value;
  }

  get supportContactExtended(): ISupportContactExtended {
    return this._supportContactExtended;
  }

  private _supportContactExtended: ISupportContactExtended;
  selectedTenant: TENANT = TENANT.Siemens;
  TENANT = TENANT;
  company = this.tenantService.getTenantString();
  supportContactItem: ISupportContactItem;
  selectedRegion: string;
  regions: IRegionItem[];

  constructor(private tenantService: TenantService, public dialog: MatDialog, private toast: ToastrService, private http: HttpClient) {}

  openEditDialog(): void {
    this.dialog.open(AddSupportContactComponent, {
      minWidth: '600px',
      width: '70vw',
      maxHeight: '95vh',
      data: { supportContactItem: this.supportContactItem, tenant: this.selectedTenant, region: this.selectedRegion, isNew: false, regions: this.regions }
    });
  }

  async deleteContact(id: string): Promise<void> {
    const URL = `${environment.api.backend}support-contacts?tenant=${this.selectedTenant}&id=${id}`;

    try {
      await this.http.delete(URL).toPromise();
      this.toast.success('Deleted item.');
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  }

  tenantSelected(tenant: TENANT): void {
    this.selectedTenant = tenant;
    this.supportContactItem = this.supportContactExtended[this.selectedTenant];
  }
}
