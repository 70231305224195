<div class="container">
  <div class="main-tile" (click)="collapsed = !collapsed">
    <div class="image-container">
      <img class="flag" src="assets/svgs/flags/{{ countryCode }}.svg" />
      <p class="country-text">{{ countryName }}</p>
    </div>
    <mat-icon [ngClass]="{ collapsed: collapsed }">
      {{ collapsed ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}
    </mat-icon>
  </div>
  <div *ngIf="!collapsed" class="information-container">
    <div *ngFor="let item of addOnUrls">
      <a [href]="item.addOnUrl" *ngIf="item.linkText">{{ item.linkText }}</a>
      <a [href]="item.addOnUrl" *ngIf="!item.linkText">{{ item.addOnUrl }}</a>
    </div>
  </div>
</div>
