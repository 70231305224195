import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { TENANT } from 'src/app/shared/Tenant.enum';
import { SupportContactsService } from 'src/app/shared/services/support-contacts.service';
import { v4 as uuid } from 'uuid';
import { ISupportContactItem } from '../../../shared/interfaces/ISupportContact';
import { IRegionItem } from './../../../shared/interfaces/IRegion';
import { CountryEnum } from './../../../shared/enums/CountryEnum';

@Component({
  selector: 'app-support-contacts',
  templateUrl: './add-support-contacts.component.html',
  styleUrls: ['./add-support-contacts.component.scss']
})
export class AddSupportContactComponent implements OnInit {
  title?: string;
  country: CountryEnum;
  organization: string;
  contact: string;
  regions: IRegionItem[];
  id: string;
  TENANT = TENANT;
  tenantValues = Object.values(TENANT);
  selectedTenant: TENANT;
  selectedRegion: string;
  supportContactItem: ISupportContactItem;
  isNewItem = true;
  editorConfig: AngularEditorConfig = {
    minHeight: '4rem',
    editable: true,
    sanitize: true,
    toolbarHiddenButtons: [
      ['strikeThrough', 'justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'heading', 'fontName', 'subscript', 'superscript'],
      ['fontSize', 'textColor', 'backgroundColor', 'customClasses', 'insertHorizontalRule', 'removeFormat', 'toggleEditorMode', 'insertImage', 'insertVideo']
    ]
  };

  countries?: string[];
  selectedCountries?: string[];
  searchCountry?: string;

  get isSaveEnabled(): boolean {
    return true;
  }

  constructor(
    public dialog: MatDialog,
    private toast: ToastrService,
    private supportContactsService: SupportContactsService,
    public dialogRef: MatDialogRef<AddSupportContactComponent>,
    @Inject(MAT_DIALOG_DATA) public data?: { supportContactItem: ISupportContactItem; tenant: TENANT; region: string; isNew: boolean; regions: IRegionItem[] }
  ) {}

  ngOnInit(): void {
    if (this.data) {
      if (this.data.isNew) {
        this.regions = this.data.regions;
      } else {
        this.isNewItem = false;
        this.id = this.data.supportContactItem.id;
        this.title = this.data.supportContactItem.title;
        this.country = this.data.supportContactItem.country;
        this.organization = this.data.supportContactItem.organization;
        this.contact = this.data.supportContactItem.contact;
        this.selectedRegion = this.data.region;
        this.selectedTenant = this.data.tenant;
        this.regions = this.data.regions;
      }
    }
    this.countries = Object.values(CountryEnum);
    // Initially fill the selectedCountries so it can be used in the for loop
    this.selectedCountries = this.countries;
  }

  closeNewSupportContactDialogue(): void {
    this.dialog.closeAll();
  }

  // Receive user input and send to search method
  onKey(): void {
    this.selectedCountries = this.search(this.searchCountry);
  }

  // Filter the states list and send back to populate the selectedCountry
  search(value: string): string[] {
    const filter = value.toLowerCase();
    return this.countries.filter(option => option.toLowerCase().startsWith(filter));
  }

  async createSupportContact(): Promise<void> {
    const contactItem = new ISupportContactItem();
    contactItem.id = this.isNewItem ? `${uuid()}` : this.data.supportContactItem.id;
    contactItem.title = this.title;
    contactItem.region = this.selectedRegion;
    contactItem.country = this.country;
    contactItem.organization = this.organization === undefined ? '' : this.organization;
    contactItem.contact = this.contact;

    await this.saveSupportContactToDBs(contactItem);
  }

  async saveSupportContactToDBs(item: ISupportContactItem): Promise<void> {
    try {
      if (this.isNewItem) {
        await this.supportContactsService.saveNewSupportContact(this.selectedTenant, item);
        this.toast.success('Added item to database.');
      } else {
        await this.supportContactsService.updateSupportContact(this.data.tenant, item);
        this.toast.success('Successfully edited item.');
      }
      this.dialog.closeAll();
      window.location.reload();
    } catch (error) {
      console.log(error);
      this.toast.error('An error occurred...');
    }
  }
}
