<div class="topic-tile-container" [ngClass]="{ selected: tile.selected }" (click)="tileClicked(tile)">
  <div class="tile" *ngIf="tile.icon">
    <img [src]="tile.icon" class="default" [ngClass]="{ small: size === 'small', large: size === 'large' }" />
    <img [src]="tile.iconHover" class="hover" [ngClass]="{ small: size === 'small', large: size === 'large' }" />
    <p *ngIf="tile.title">{{ tile.title }}</p>
  </div>
  <div class="content" *ngIf="tile.matIcon">
    <p *ngIf="tile.title">{{ tile.title }}</p>
    <div *ngIf="tile.matIcon" class="icon-container" [ngClass]="tile.position || 'center'">
      <mat-icon [style.color]="tile.iconColor">{{ tile.matIcon }}</mat-icon>
    </div>
  </div>
</div>
