<div class="admin-page">
  <h2>{{ 'admin-page.title' | translate }}</h2>

  <div *ngIf="tenantService.isTenantSiemens()">
    <div *ngIf="userRole === UserRoleEnum.ADMIN">
      <h3>{{ 'admin-page.general' | translate }}</h3>
      <div class="tiles">
        <app-topics-tile *ngFor="let tile of tiles" [tile]="tile" [routerLink]="tile.url"> </app-topics-tile>
      </div>
    </div>
    <div *ngIf="userRole === UserRoleEnum.ADMIN || userRole === UserRoleEnum.CONTENT_MANAGER">
      <h3>{{ 'admin-page.cms' | translate }}</h3>
      <div class="tiles">
        <app-topics-tile *ngFor="let tile of cmsTiles" [tile]="tile" [routerLink]="tile.url"> </app-topics-tile>
      </div>
    </div>
    <div *ngIf="userRole === UserRoleEnum.ADMIN || userRole === UserRoleEnum.CONTENT_MANAGER">
      <h3>{{ 'admin-page.reports' | translate }}</h3>
      <button class="button" (click)="openExternalLink()">Go to Analytics</button>
    </div>
  </div>
</div>
