import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { TENANT } from '../Tenant.enum';
import { TenantService } from './tenant.service';

@Injectable({
  providedIn: 'root'
})
export class TenantGuard implements CanActivate {
  private readonly unauthorizedUrl = '/unauthorized';

  constructor(private router: Router, private tenantService: TenantService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const allowedTenants: TENANT[] = route.data?.tenantsAllowed ?? [];
    const isAllowed = allowedTenants.includes(this.tenantService.getTenant());

    if (!isAllowed) {
      void this.router.navigate([this.unauthorizedUrl]);
    }

    return isAllowed;
  }
}
