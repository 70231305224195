import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent {
  @Input() textTranslation: string;
  @Input() buttonTranslation: string;

  @Input() multiple = false;
  @Input() accept = '.*';

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onFilesChange = new EventEmitter<File[]>();

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  onDragOver(event): void {
    event.preventDefault();
    event.stopPropagation();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  onDragLeave(event): void {
    event.preventDefault();
    event.stopPropagation();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  onDrop(event): void {
    event.preventDefault();
    event.stopPropagation();

    const files: File[] = event.dataTransfer.files;
    if (files.length <= 0) {
      return;
    }
    this.handleFileChange(files);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  handleFileChange(event: any): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    this.onFilesChange.emit(event.target.files);
  }
}
