<div class="add-faq-dialogue" mat-dialog-content>
  <div class="close-dialogue-icon" mat-icon (click)="closeNewFaqDialogue()">
    <i class="material-icons">close</i>
  </div>
  <h2>{{ 'admin-page.whats-new.add.title-edit' | translate }}</h2>
  <div class="edit-container">
    <div class="main-tile">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'admin-page.whats-new.add.title-text' | translate }} - If empty, this section won't be shown in Whats New section on homepage.</mat-label>
        <input type="text" matInput [(ngModel)]="whatsNewItem.title" />
        <!-- <input type="text" matInput required [(ngModel)]="whatsNewItem.title"> -->
        <!-- <mat-error *ngIf="!whatsNewItem.title || whatsNewItem.title.trim().length === 0">
                    {{ 'admin-page.warning' | translate }}
                </mat-error> -->
      </mat-form-field>

      <mat-form-field appearance="fill" class="no-margin">
        <mat-label>{{ 'admin-page.help-topics.add.tenant' | translate }}</mat-label>
        <mat-select [(ngModel)]="selectedTenant" required [disabled]="!isNewItem">
          <mat-option *ngFor="let tenant of tenantValues" [value]="tenant">{{ tenant }}</mat-option>
        </mat-select>
        <mat-error *ngIf="!selectedTenant">
          {{ 'admin-page.warning' | translate }}
        </mat-error>
      </mat-form-field>

      <div *ngIf="data.whatsNewItem.id !== 'whatsNew|section3'" class="text-editor">
        <h4>{{ 'admin-page.whats-new.add.content-text' | translate }}</h4>
        <angular-editor [placeholder]="'admin-page.faq.edit.answer-placeholder' | translate" [config]="editorConfig" [(ngModel)]="whatsNewItem.content"> </angular-editor>
        <p class="warning" *ngIf="!whatsNewItem.content || whatsNewItem.content.trim().length === 0">{{ 'admin-page.warning' | translate }}</p>
      </div>
    </div>

    <mat-expansion-panel *ngIf="data.whatsNewItem.id === 'whatsNew|section3'">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'admin-page.help-topics.add.new-link-text' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-form-field appearance="fill">
        <mat-label>{{ 'admin-page.whats-new.add.title-text' | translate }} - If empty, this section won't be shown in Whats New section on homepage.</mat-label>
        <input type="text" matInput [(ngModel)]="whatsNewItem.title" />
        <!-- <input type="text" matInput required [(ngModel)]="whatsNewItem.title"> -->
        <!-- <mat-error *ngIf="!whatsNewItem.title || whatsNewItem.title.trim().length === 0">
              {{ 'admin-page.warning' | translate }}
          </mat-error> -->
      </mat-form-field>

      <div class="item-list-container">
        <div *ngFor="let link of data.whatsNewItem.links; let idx = index" class="item-container">
          <mat-form-field appearance="fill">
            <mat-label>{{ 'admin-page.help-topics.add.links.label' | translate }}</mat-label>
            <input type="text" matInput required [(ngModel)]="link.label" />
            <mat-error *ngIf="!link.label || link.label.trim().length === 0">
              {{ 'admin-page.warning' | translate }}
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>{{ 'admin-page.help-topics.add.links.link-url' | translate }}</mat-label>
            <input type="text" matInput checked required [(ngModel)]="link.linkUrl" [disabled]="!isSaveEnabled" />
            <mat-error *ngIf="!link.linkUrl || link.linkUrl.trim().length === 0">
              {{ 'admin-page.warning' | translate }}
            </mat-error>
            <div *ngIf="!link.linkUrl?.startsWith('https://')">
              <mat-error>
                {{ 'admin-page.whats-new.edit.https-required' | translate }}
              </mat-error>
            </div>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>{{ 'admin-page.help-topics.add.links.sort-value' | translate }}</mat-label>
            <input type="number" matInput checked required [(ngModel)]="link.sortOrder" [disabled]="!isSaveEnabled" />
            <mat-error *ngIf="!link.sortOrder">
              {{ 'admin-page.warning' | translate }}
            </mat-error>
          </mat-form-field>

          <div class="action-buttons">
            <app-action-button design="stroke" fill="warn" (click)="deleteLink(idx)">
              <mat-icon class="button-icon">delete</mat-icon><span>{{ 'admin-page.help-topics.edit.buttons.delete' | translate }}</span>
            </app-action-button>
          </div>
        </div>
      </div>

      <button class="fab-add-button" mat-fab color="primary" aria-label="Add new Link" (click)="addLink()">
        <mat-icon>add</mat-icon>
      </button>
    </mat-expansion-panel>

    <app-action-button class="save-button" [disabled]="!isSaveEnabled" (click)="createWhatsNew()">
      {{ 'general.save' | translate }}
    </app-action-button>
  </div>
</div>
