import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { TenantService } from 'src/app/shared/services/tenant.service';
import { filter, takeUntil } from 'rxjs/operators';
import { CategoryEnum } from 'src/app/shared/interfaces/ICategory';
import { ToastrService } from 'ngx-toastr';
import { IHelpItem } from '../../shared/interfaces/IHelpItem';
import { HelpItemService } from '../../shared/services/help-item.service';
import { LoadingService } from '../../shared/services/loading.service';

@Component({
  selector: 'app-help-topics-subpage',
  templateUrl: './help-topics-subpage.component.html',
  styleUrls: ['./help-topics-subpage.component.scss']
})
export class HelpTopicsSubpageComponent implements OnInit, OnDestroy {
  CategoryEnum = CategoryEnum;
  private destroy$ = new Subject<void>();

  company = this.tenantService.getTenantString();
  topicId: string;
  items: IHelpItem[] = [];
  categoryName?: CategoryEnum;

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private router: Router,
    private tenantService: TenantService,
    private helpItemService: HelpItemService,
    private loadingService: LoadingService,
    private toast: ToastrService
  ) {}

  ngOnInit(): void {
    this.loadingService.present();

    this.route.params.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.topicId = this.route.snapshot.paramMap.get('help-topic');
    });

    this.helpItemService
      .fetchHelpTiles()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        value => {
          // Get the name of the category currently selected
          this.categoryName = value.find(tile => tile.url === this.router.url.split('?')[0]).title as CategoryEnum;

          if (this.categoryName) {
            // Load items in that category from the BE
            this.helpItemService.getHelpItemByCategory(this.company, this.categoryName).subscribe(result => {
              this.items = result.sort((a, b) => a.sortOrder - b.sortOrder);
              this.items.map(e => {
                e.addOns.sort((a, b) => a.label.localeCompare(b.label));
              });

              // sort the url links in county addOns per orderValue
              this.items.map(e => {
                e.addOns.map(countryAddOn => {
                  countryAddOn.addOnUrls.sort((a, b) => a.orderValue - b.orderValue);
                });
              });

              this.loadingService.dismiss();

              // Once we have all the items, if the id query parameter was passed, we directly scroll to that item.
              this.getHighlightedTopicId();
            });
          }
        },
        () => {
          this.loadingService.dismiss();
          this.toast.error('An error occurred while retrieving data from the server');
        }
      );
  }

  private getHighlightedTopicId() {
    this.route.queryParams
      .pipe(
        filter(params => params.id !== undefined),
        takeUntil(this.destroy$)
      )
      .subscribe(params => {
        const highlightedId: string = params.id;

        const highlightedItem = this.items.find(item => item.id === 'helpItem|' + highlightedId);

        if (!highlightedItem) {
          this.toast.warning('Could not find help topic with specified id.');
          console.log('Could not find help topic with specified id.');
          return;
        }

        setTimeout(() => {
          // Timeout in order to wait until the page has fully loaded before querying the html element
          const element: HTMLElement = document.querySelector('app-most-relevant-item[data-id="' + highlightedItem.id + '"] app-accordion > div');

          if (element) {
            // Open up the highlighted element
            (element.firstElementChild as HTMLElement).click();
            element.scrollIntoView();
          }
        }, 0);
      });
  }

  onClickBack(): void {
    void this.router.navigate(['/home']);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
