import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { SupportContactsService } from 'src/app/shared/services/support-contacts.service';
import { v4 as uuid } from 'uuid';
import { IRegionItem } from './../../../shared/interfaces/IRegion';

@Component({
  selector: 'app-add-region',
  templateUrl: './add-region.component.html',
  styleUrls: ['./add-region.component.scss']
})
export class AddRegionComponent {
  region: string;
  isNewItem = true;
  editorConfig: AngularEditorConfig = {
    minHeight: '4rem',
    editable: true,
    sanitize: true,
    toolbarHiddenButtons: [
      ['strikeThrough', 'justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'heading', 'fontName', 'subscript', 'superscript'],
      ['fontSize', 'textColor', 'backgroundColor', 'customClasses', 'insertHorizontalRule', 'removeFormat', 'toggleEditorMode', 'insertImage', 'insertVideo']
    ]
  };
  get isSaveEnabled(): boolean {
    return true;
  }

  constructor(public dialog: MatDialog, private toast: ToastrService, private supportContactService: SupportContactsService, public dialogRef: MatDialogRef<AddRegionComponent>) {}

  closeNewSupportContactDialogue(): void {
    this.dialog.closeAll();
  }

  async createRegion(): Promise<void> {
    const regionItem = new IRegionItem();
    regionItem.region = this.region;
    regionItem.id = `${uuid()}`;

    await this.saveRegionToDBs(regionItem);
  }

  async saveRegionToDBs(regionItem: IRegionItem): Promise<void> {
    try {
      if (this.isNewItem) {
        await this.supportContactService.saveNewRegion(regionItem);
        this.toast.success('Added item to database.');
      } else {
        await this.supportContactService.saveNewRegion(regionItem);
        this.toast.success('Successfully edited item.');
      }
      this.dialog.closeAll();
      window.location.reload();
    } catch (error) {
      console.log(error);
      this.toast.error('An error occurred...');
    }
  }
}
