<div class="container">
  <div>
    <a [routerLink]="'/admin'" class="container__back">
      <mat-icon class="icon-display">keyboard_arrow_left</mat-icon>
      {{ 'admin-page.help-topics.buttons.back-button' | translate }}
    </a>
  </div>
  <div class="main">
    <h3>{{ 'admin-page.help-topics.title' | translate }}</h3>
    <button mat-button (click)="openNewFaqDialogue()">
      {{ 'admin-page.help-topics.buttons.add-new' | translate }}
    </button>
  </div>
  <p>{{ 'admin-page.help-topics.text' | translate }}</p>
  <!-- ALL Help Topics per CATEGORY -->
  <div *ngFor="let category of this.categoryKeys">
    <h2>
      {{ this.getCategoryValue(category) }}
    </h2>
    <div class="tiles">
      <app-edit-help-topic *ngFor="let item of getQuestionsByCategory(category)" [extendedItem]="item" [attr.data-id]="item.id"> </app-edit-help-topic>
    </div>
  </div>
</div>
