<div *ngIf="supportContactItem">
  <div class="container">
    <app-accordion [title]="supportContactItem.title || supportContactItem.country + ' ' + (supportContactItem.organization || '')" design="faq">
      <div class="tenants">
        <div class="tenant" *ngIf="supportContactExtended.siemens" [class.active]="selectedTenant === TENANT.Siemens" (click)="tenantSelected(TENANT.Siemens)">
          <img src="./assets/svgs/logo-siemens.svg" />
        </div>
        <div class="tenant" *ngIf="supportContactExtended.shs" [class.active]="selectedTenant === TENANT.SHS" (click)="tenantSelected(TENANT.SHS)">
          <img src="./assets/svgs/logo-healthineers.svg" />
        </div>
        <div class="tenant" *ngIf="supportContactExtended.siemensenergy" [class.active]="selectedTenant === TENANT.SE" (click)="tenantSelected(TENANT.SE)">
          <img src="./assets/svgs/logo-energy-no-padding.svg" />
        </div>
      </div>
      <div class="detail-container">
        <div class="process-items">
          <div [innerHTML]="supportContactItem.contact"></div>
        </div>

        <div class="action-buttons">
          <app-action-button design="stroke" fill="primary" (click)="openEditDialog()">
            <mat-icon class="button-icon">edit</mat-icon><span>{{ 'admin-page.help-topics.edit.buttons.edit' | translate }}</span>
          </app-action-button>
          <app-action-button *ngIf="supportContactItem.id !== 'contact|text'" design="stroke" fill="warn" (click)="deleteContact(supportContactItem.id)">
            <mat-icon class="button-icon">delete</mat-icon><span>{{ 'admin-page.help-topics.edit.buttons.delete' | translate }}</span>
          </app-action-button>
        </div>
      </div>
    </app-accordion>
  </div>
</div>
