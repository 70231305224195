<div class="container">
  <nav>
    <div class="logo">
      <a href="/"></a>
    </div>

    <div class="nav-items">
      <div>
        <a *ngFor="let elem of headerElements" (click)="onItemClick($event, elem.elemId)" [className]="'nav-' + elem.elemId">
          {{ elem.displayName | translate }}
        </a>
        <span class="slider" [style.width]="sliderWidth" [style.left]="sliderOffset"></span>
      </div>
    </div>

    <div class="right-part">
      <mat-icon (click)="openSupportDialog()">contact_support</mat-icon>

      <div class="user">
        <div class="user-names">
          <div>
            <p>{{ (user$ | async).firstName }}</p>
            <p>{{ (user$ | async).lastName }}</p>
          </div>
        </div>
        <a routerLink="/admin"><p class="admin-pill" *ngIf="userRole === UserRoleEnum.ADMIN">ADMIN</p></a>
        <a routerLink="/admin"><p class="cm-pill" *ngIf="userRole === UserRoleEnum.CONTENT_MANAGER">CONTENT MANAGER</p></a>
      </div>
    </div>
  </nav>
</div>
