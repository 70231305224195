import { Component, Input } from '@angular/core';
import { IHelpItem } from 'src/app/shared/interfaces/IHelpItem';
import { TrackingService } from 'src/app/shared/services/tracking.service';
import * as copy from 'copy-to-clipboard';
import { HelpItemService } from '../../shared/services/help-item.service';
import { IHelpItemLink } from '../../shared/interfaces/IHelpItemLink';

@Component({
  selector: 'app-most-relevant-item',
  templateUrl: './most-relevant-item.component.html',
  styleUrls: ['./most-relevant-item.component.scss']
})
export class MostViewedItemComponent {
  constructor(private trackingService: TrackingService, public helpItemService: HelpItemService) {}
  @Input() item: IHelpItem;

  // If we show "Copied to clipboard" message
  public showJustShared = false;

  addTracking(item: IHelpItem): void {
    void this.trackingService.addWCSTrackingItem('help-item', item.title);
  }

  addTrackingLink(link: IHelpItemLink): void {
    void this.trackingService.addWCSTrackingItem('helpItem-link', link.label);
  }

  async copyShareLink(): Promise<void> {
    const categoryUrl = await this.helpItemService.getCategoryUrl(this.item.category).toPromise();
    this.showJustShared = true;
    // Copies to clipboard
    copy(`${window.origin}${categoryUrl}?id=${this.item.id.substring(9)}`);

    setTimeout(() => {
      this.showJustShared = false;
    }, 2000);
  }
}
