<div class="add-faq-dialogue" mat-dialog-content>
  <div class="close-dialogue-icon" mat-icon (click)="closeNewFaqDialogue()">
    <i class="material-icons">close</i>
  </div>
  <h2>{{ (isNewQuestion ? 'admin-page.faq.add.title' : 'admin-page.faq.add.title-edit') | translate }}</h2>
  <div class="edit-container">
    <div class="main-tile">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'admin-page.faq.add.question-text' | translate }}</mat-label>
        <input type="text" matInput required [(ngModel)]="questionText" />
        <mat-error *ngIf="!questionText || questionText.trim().length === 0">
          {{ 'admin-page.warning' | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="no-margin">
        <mat-label>{{ 'admin-page.help-topics.add.tenant' | translate }}</mat-label>
        <mat-select [(ngModel)]="selectedTenant" required [disabled]="!isNewQuestion">
          <mat-option *ngFor="let tenant of tenantValues" [value]="tenant">{{ tenant }}</mat-option>
        </mat-select>
        <mat-error *ngIf="!selectedTenant">
          {{ 'admin-page.warning' | translate }}
        </mat-error>
      </mat-form-field>

      <div class="text-editor">
        <h4>{{ 'admin-page.faq.add.answer-text' | translate }}</h4>
        <angular-editor [placeholder]="'admin-page.faq.edit.answer-placeholder' | translate" [config]="editorConfig" [(ngModel)]="answer"> </angular-editor>
        <p class="warning" *ngIf="!answer || answer.trim().length === 0">{{ 'admin-page.warning' | translate }}</p>
      </div>

      <mat-form-field appearance="fill">
        <mat-label>{{ 'admin-page.faq.add.category.title' | translate }}</mat-label>
        <mat-select matNativeControl [(ngModel)]="selectedCategory" required>
          <mat-option value="Country Specific Training Materials">Country Specific Training Materials</mat-option>
          <mat-option value="Reporting Topics">Reporting Topics</mat-option>
          <mat-option value="Absence Topics (available for some countries)">Absence Topics (available for some countries)</mat-option>
          <mat-option value="Manager Topics">Manager Topics</mat-option>
          <mat-option value="Employee Topics">Employee Topics</mat-option>
          <mat-option value="General Information & First Steps">General Information & First Steps</mat-option>
        </mat-select>
        <mat-error *ngIf="!selectedCategory">
          {{ 'admin-page.warning' | translate }}
        </mat-error>
      </mat-form-field>
    </div>

    <app-action-button class="save-button" [disabled]="!isSaveEnabled" (click)="createFaq(questionText, answer, selectedCategory)">
      {{ 'general.save' | translate }}
    </app-action-button>
  </div>
</div>
