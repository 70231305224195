import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.scss']
})
export class ActionButtonComponent {
  @Input() fill: 'primary' | 'warn' = 'primary';
  @Input() design: 'filled' | 'stroke' = 'filled';
  @Input() disabled = false;

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  onClick(event: MouseEvent) {
    if (this.disabled) {
      event.stopPropagation();
    }
  }
}
