<div class="container">
  <h2>translations.title</h2>

  <div class="content">
    <mat-form-field class="search" appearance="fill">
      <mat-label>translations.search</mat-label>
      <input matInput />
    </mat-form-field>

    <app-translation-accordion [translations]="translations"></app-translation-accordion>

    <app-action-button (click)="createNewTopic(translations)">translations.add-topic</app-action-button>
    <app-action-button (click)="uploadTranslations()">translations.upload</app-action-button>
  </div>
</div>
