import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngxs/store';
import { environment } from '../../../environments/environment';
import { UserRoleEnum } from '../../shared/enums/UserRoleEnum';
import { UserState } from '../../auth/store';
import { LoadingService } from '../../shared/services/loading.service';
import { AddNewUserDialogComponent } from './add-new-user-dialog/add-new-user-dialog.component';

interface IUser {
  gid: string;
  role: UserRoleEnum;
  firstName?: string;
  lastName?: string;
}

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {
  constructor(private store: Store, private http: HttpClient, public dialog: MatDialog, private toast: ToastrService, private loadingService: LoadingService) {}

  searchValue = '';

  users: IUser[];
  displayedUsers: IUser[];
  sortedUsers: IUser[];
  UserRoleEnumKeys = Object.keys(UserRoleEnum);
  UserRoleEnum = UserRoleEnum;
  sortConfig: {
    by: 'lastName' | 'role' | 'none';
    order: 'asc' | 'desc' | 'none';
  } = { by: 'none', order: 'none' };

  ngOnInit(): void {
    this.fetchAllUsers();
  }

  fetchAllUsers(): void {
    this.loadingService.present();
    this.http.get<IUser[]>(`${environment.api.backend}user/all`).subscribe(res => {
      this.loadingService.dismiss();
      this.users = res;
      this.sortedUsers = res;
      this.onSearch();
    });
  }

  onSearch(): void {
    if (!this.searchValue || this.searchValue.trim().length === 0) {
      this.displayedUsers = this.users;
    }

    this.displayedUsers = this.users.filter(
      user => user.role.toLowerCase().includes(this.searchValue.toLowerCase()) || user.gid.toLowerCase().includes(this.searchValue.toLowerCase())
    );
  }

  openAddNewUser(): void {
    const dialogRef = this.dialog.open(AddNewUserDialogComponent, {
      minWidth: '600px',
      width: '70vw',
      maxHeight: '95vh'
    });

    dialogRef.afterClosed().subscribe(shouldRefresh => {
      if (shouldRefresh) {
        this.fetchAllUsers();
      }
    });
  }

  deleteUser(gid: string): void {
    this.http.delete(`${environment.api.backend}user?gid=${gid}`).subscribe(
      () => {
        this.toast.success('Successfully deleted user');
        if (gid === this.store.selectSnapshot(UserState.loggedUser).gid) {
          // User deleted themselves
          window.location.reload();
        } else {
          this.fetchAllUsers();
        }
      },
      () => this.toast.error('Error deleting user...')
    );
  }

  updateUser(gid: string, prevRole: UserRoleEnum, newRole: UserRoleEnum, firstName: string, lastName: string): void {
    this.http.put(`${environment.api.backend}user`, { gid, role: newRole, firstName, lastName }).subscribe(
      () => {
        this.toast.success('Successfully updated the role');
        if (gid === this.store.selectSnapshot(UserState.loggedUser).gid) {
          // User edited themselves
          window.location.reload();
        }
      },
      () => {
        this.toast.error('Error updating the role');
        const userIndex = this.users.findIndex(user => user.gid === gid);
        if (userIndex !== -1) {
          // Display the old role back in the FE
          this.users[userIndex].role = prevRole;
          // Hacky way to refresh the list with the mat-select components, so that they show the old role correctly in the FE
          this.displayedUsers = [];
          setTimeout(() => this.onSearch(), 0);
        }
      }
    );
  }

  sortUsers(attribute: 'lastName' | 'role'): void {
    this.getSortingConfig(attribute);
    if (this.sortConfig.by === 'none') {
      this.displayedUsers = [...this.displayedUsers];
    } else if (this.sortConfig.order === 'asc') {
      this.displayedUsers = this.displayedUsers.sort((a, b) => (a[attribute].toLowerCase() > b[attribute].toLowerCase() ? 1 : -1));
    } else {
      this.displayedUsers = this.displayedUsers.sort((a, b) => (a[attribute].toLowerCase() > b[attribute].toLowerCase() ? -1 : 1));
    }
  }

  private getSortingConfig(attribute: 'lastName' | 'role'): void {
    if (this.sortConfig.by === attribute) {
      if (this.sortConfig.order === 'none') {
        this.sortConfig.order = 'asc';
      } else if (this.sortConfig.order === 'asc') {
        this.sortConfig.order = 'desc';
      } else {
        this.sortConfig = { by: 'none', order: 'none' };
      }
    } else {
      this.sortConfig = { by: attribute, order: 'asc' };
    }
  }
}
