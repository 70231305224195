<section id="home" style="padding: 0">
  <div class="hero" [ngClass]="tenantService.getTenant().toString()">
    <div class="heading">
      <h1>{{ 'home.title' | translate }}</h1>
      <h2>{{ 'home.text' | translate }}</h2>
    </div>
  </div>
</section>

<app-critical-info></app-critical-info>

<app-help-topics id="help-topics"></app-help-topics>

<app-faq id="faq" [category]="CategoryEnum.GENERAL" [count]="10"></app-faq>

<app-most-relevant id="most-relevant"></app-most-relevant>
