import { Injectable, NgZone } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable()
export class UnauthorizedInterceptorService implements HttpInterceptor {
  constructor(private router: Router, private toast: ToastrService, private ngZone: NgZone) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return next.handle(request).pipe(
      catchError(err => {
        if (err.status === 403) {
          if (!this.router.routerState.snapshot.url) {
            void this.ngZone.run(() => this.router.navigate(['unauthorized']));
          } else {
            void this.ngZone.run(() => this.toast.error('Not authorized.'));
          }
          return of(err);
        } else {
          return throwError(err);
        }
      })
    );
  }
}
