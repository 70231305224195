import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CategoryEnum } from 'src/app/shared/interfaces/ICategory';
import { FaqService } from 'src/app/shared/services/faq.service';
import { HelpItemService } from 'src/app/shared/services/help-item.service';
import { mergeMap, toArray, tap, takeUntil, Subject } from 'rxjs';
import { TopicsTileInterface } from 'src/app/shared/interfaces/topics-tile.interface';
import { LoadingService } from '../../shared/services/loading.service';
import { CsvHelperService } from './../../shared/services/csv-helper.service';
import { AddFaqComponent } from './add-faq/add-faq.component';
import { IQuestionExtended } from './../../shared/interfaces/IQuestion';
import { CmsFaqOperations } from './cms-faqs.operations';

@Component({
  selector: 'app-cms-faqs',
  templateUrl: './cms-faqs.component.html',
  styleUrls: ['./cms-faqs.component.scss']
})
export class CmsFaqsComponent implements OnInit, OnDestroy {
  protected CmsFaqOperations = CmsFaqOperations;
  protected categories: boolean[];
  protected allTrueInitially = true;
  protected keys: string[];
  protected categoryKeys = Object.keys(CategoryEnum);
  protected questions: IQuestionExtended[];
  protected searchValue = '';
  protected searchResult: IQuestionExtended[];
  protected searchExceededMessage = '';
  protected tiles: TopicsTileInterface[] = [];
  protected selectedCategories: string[] = [];
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public dialog: MatDialog,
    private faqService: FaqService,
    private loadingService: LoadingService,
    private csvHelper: CsvHelperService,
    private helpTopicsService: HelpItemService
  ) {}

  async ngOnInit(): Promise<void> {
    this.loadingService.present();
    this.questions = await this.faqService.getAllForCMS();
    this.getHelpTopicsForCategories();
    this.getCategories();
    this.loadingService.dismiss();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  protected openNewFaqDialogue(): void {
    this.dialog.open(AddFaqComponent, {
      minWidth: '600px',
      width: '70vw',
      maxHeight: '95vh'
    });
  }

  protected getQuestionsByCategory(category: string): IQuestionExtended[] {
    if (!this.searchResult || this.searchValue === '') {
      if (!this.questions) {
        return [];
      }
      return this.questions.filter(question => question.siemens?.category === category || question.shs?.category === category || question.siemensenergy?.category === category);
    } else {
      return this.searchResult.filter(question => question.siemens?.category === category || question.shs?.category === category || question.siemensenergy?.category === category);
    }
  }

  protected startSearch(value: string): void {
    if (!value) {
      this.clearSearch();
      return;
    }
    if (!this.questions) {
      return;
    }
    this.searchValue = value;
    this.searchResult = [];
    const filteredQuestions: IQuestionExtended[] = [];

    const searchTerms = value.toLowerCase().split(/\s+/);

    for (const question of this.questions) {
      const fieldsToSearch = [
        question.siemens?.answer,
        question.siemens?.question,
        question.shs?.answer,
        question.shs?.question,
        question.siemensenergy?.answer,
        question.siemensenergy?.question
      ]
        .filter(field => field !== undefined)
        .map(field => field.toLowerCase());

      const matchesAllTerms = searchTerms.every(term => fieldsToSearch.some(field => field.includes(term)));

      if (matchesAllTerms) {
        filteredQuestions.push(question);
      }
    }

    this.searchResult = filteredQuestions;
  }

  protected handleCategoryClicked(category: CategoryEnum, index: number): void {
    if (this.allTrue() && this.allTrueInitially) {
      this.allTrueInitially = false;
      for (const key of this.keys) {
        this.categories[key] = false;
      }
    }

    this.categories[category] = !this.categories[category];
    this.toggleSelectedCategory(category);
    this.toggleTileSelected(index);

    if (this.allFalse()) {
      this.allTrueInitially = true;
      for (const key of this.keys) {
        this.categories[key] = true;
      }
    }
  }

  protected clearSearch(): void {
    this.searchValue = '';
    this.searchResult = undefined;
  }

  protected getCategoryValue(key: string): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return CategoryEnum[key];
  }

  protected exportAsCSV(questions: IQuestionExtended[]): void {
    const downloadData = CmsFaqOperations.createObjectsForCsvDownload(questions);
    this.csvHelper.downloadRecordsAsCsv(downloadData.shsData);
    this.csvHelper.downloadRecordsAsCsv(downloadData.siemensData);
    this.csvHelper.downloadRecordsAsCsv(downloadData.siemensenergyData);
  }

  private getCategories(): void {
    if (this.questions !== undefined) {
      this.categories = [];
      for (const categoryEnum of Object.values(CategoryEnum)) {
        this.categories[categoryEnum] = true;
      }
    }
    this.keys = Object.keys(this.categories);
  }

  private getHelpTopicsForCategories(): void {
    this.helpTopicsService
      .fetchHelpTiles()
      .pipe(
        mergeMap((tiles: TopicsTileInterface[]) => tiles),
        toArray(),
        tap((tiles: TopicsTileInterface[]) => {
          this.tiles = tiles;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private toggleTileSelected(index: number): void {
    this.tiles[index].selected = !this.tiles[index].selected;
  }

  private allTrue(): boolean {
    let allTrue = true;
    for (const key of this.keys) {
      allTrue = allTrue && this.categories[key];
    }
    return allTrue;
  }

  private allFalse(): boolean {
    let allFalse = true;
    for (const key of this.keys) {
      allFalse = allFalse && !this.categories[key];
    }
    return allFalse;
  }

  private toggleSelectedCategory(category: CategoryEnum): void {
    const index = this.selectedCategories.indexOf(category);
    if (index === -1) {
      this.selectedCategories.push(category);
    } else {
      this.selectedCategories.splice(index, 1);
    }
  }
}
