<ng-container *ngIf="asLink">
  <div class="tiles-container">
    <div *ngFor="let tile of tiles" class="tile">
      <app-topics-tile [tile]="tile" [routerLink]="tile.url" [size]="size"></app-topics-tile>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!asLink">
  <div class="tiles-container">
    <div *ngFor="let tile of tiles; let i = index" class="tile">
      <app-topics-tile [tile]="tile" (itemClicked)="onTileClicked(tile, i)" [size]="size"></app-topics-tile>
    </div>
  </div>
</ng-container>
