import { Component, Input } from '@angular/core';
import { TenantService } from 'src/app/shared/services/tenant.service';
import { MatDialog } from '@angular/material/dialog';
import { TENANT } from 'src/app/shared/Tenant.enum';
import { AddWhatsNewComponent } from '../add-whats-new/add-whats-new.component';
import { WhatsNewItem, WhatsNewItemExtended } from './../../../shared/interfaces/IWhatsNewExtended';

@Component({
  selector: 'app-edit-whats-new',
  templateUrl: './edit-whats-new.component.html',
  styleUrls: ['./edit-whats-new.component.scss']
})
export class EditWhatsNewComponent {
  @Input() set WhatsNewItemExtended(value: WhatsNewItemExtended) {
    this._extendedWhatsNew = value;
    this.selectedTenant = this.extendedWhatsNew[TENANT.Siemens] ? TENANT.Siemens : this.extendedWhatsNew[TENANT.SHS] ? TENANT.SHS : TENANT.SE;
    this.whatsNewItem = value[this.selectedTenant];
  }

  get extendedWhatsNew(): WhatsNewItemExtended {
    return this._extendedWhatsNew;
  }
  private _extendedWhatsNew: WhatsNewItemExtended;
  selectedTenant: TENANT = TENANT.Siemens;
  TENANT = TENANT;
  company = this.tenantService.getTenantString();
  whatsNewItem: WhatsNewItem;

  constructor(private tenantService: TenantService, public dialog: MatDialog) {}

  openEditDialog(): void {
    this.dialog.open(AddWhatsNewComponent, {
      minWidth: '600px',
      width: '70vw',
      maxHeight: '95vh',
      data: { whatsNewItem: this.whatsNewItem, tenant: this.selectedTenant }
    });
  }

  tenantSelected(tenant: TENANT): void {
    this.selectedTenant = tenant;
    this.whatsNewItem = this.extendedWhatsNew[this.selectedTenant];
  }
}
