<!-- SEARCH -->
<div class="search-container" *ngIf="showTextSearch">
  <div class="input-container">
    <mat-form-field>
      <mat-label>{{ 'faq.all.search' | translate }}</mat-label>
      <input matInput aria-label="search" (ngModelChange)="startSearch($event)" [(ngModel)]="searchValue" [placeholder]="'faq.search' | translate" type="text" />
      <button *ngIf="searchValue" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <p *ngIf="amountOfSearchHits">{{ amountOfSearchHits }} {{ amountOfSearchHits === 1 ? 'item' : 'items' }} found</p>
  </div>
</div>

<!-- CATEGORY FILTERS -->
<div class="categories-container" *ngIf="showCategorySearch">
  <app-topic-tiles-container [tiles]="categoryTiles" [asLink]="false" [size]="'small'" (tileClicked)="handleCategoryClicked($event.tile.title, $event.tileIndex)" />
</div>
