import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-videoplayer',
  templateUrl: './videoplayer.component.html',
  styleUrls: ['./videoplayer.component.scss']
})
export class VideoplayerComponent {
  @Input() videolink: string;
  @Input() autoplay: boolean;

  getType(): string {
    return 'video/mp4';
  }
}
