import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent {
  @Input() title;
  @Input() design: 'most-relevant' | 'faq' = 'most-relevant';
  collapsed = true;

  toggleAccordion(): void {
    this.collapsed = !this.collapsed;
  }
}
