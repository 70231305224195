import { Injectable, OnDestroy } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
import { UserOperations } from 'src/app/shared/operations/user.operations';
import { UserService } from 'src/app/shared/services/user.service';
import { UserRoleEnum } from '../../shared/enums/UserRoleEnum';
import { AuthUser } from '../models/auth-user.model';
import { AuthService } from '../services/auth.service';
import { GetAuthUser, LoadUserRole } from './user.actions';

export interface UserStateModel {
  isAuthenticated: boolean;
  loggedUser: AuthUser;
  role: UserRoleEnum;
  token: string;
}

@State<UserStateModel>({
  name: 'userState',
  defaults: {
    isAuthenticated: false,
    loggedUser: undefined,
    role: undefined,
    token: null
  }
})
@Injectable()
export class UserState implements OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private authService: AuthService, private userService: UserService) {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  @Selector()
  static user(state: UserStateModel): UserStateModel {
    return state;
  }

  @Selector()
  static loggedUser(state: UserStateModel): AuthUser {
    return state.loggedUser;
  }

  @Selector()
  static token(state: UserStateModel): string {
    return state.token;
  }

  @Selector()
  static role(state: UserStateModel): UserRoleEnum {
    return state.role;
  }

  @Action(GetAuthUser)
  GetAuthUser(ctx: StateContext<UserStateModel>): void {
    this.authService
      .getAuthInfo()
      .pipe(
        tap(authData => {
          ctx.patchState({
            loggedUser: UserOperations.idTokenToUserData(authData.idToken),
            isAuthenticated: authData.isAuthenticated,
            token: authData.accessToken
          });
          ctx.dispatch(new LoadUserRole('z003cw0f'));
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  @Action(LoadUserRole)
  LoadUserRole(ctx: StateContext<UserStateModel>, payload: { gid: string }): void {
    this.userService
      .getUserRole(payload.gid)
      .pipe(
        tap(role => {
          ctx.patchState({
            role
          });
        }),
        take(1)
      )
      .subscribe();
  }
}
