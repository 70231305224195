<section id="home" style="padding: 0px">
  <div [ngClass]="{ smaller: !data }" class="hradmin container banner-picture">
    <div *ngIf="data" class="banner-content">
      <div class="row heading">
        <h1 class="title" [innerHTML]="data.title"></h1>
        <h1 class="slogan">
          {{ data.slogan }}
          <img src="./assets/images/cloud.png" />
        </h1>
        <p>Start looking here if you have any questions about processes in Workday</p>
      </div>
      <div class="row description" [innerHtml]="data.content"></div>
    </div>
  </div>
</section>
