import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-upload-video-dialogue',
  templateUrl: './upload-video-dialogue.component.html',
  styleUrls: ['./upload-video-dialogue.component.scss']
})
export class UploadVideoDialogueComponent {
  @Input() filename: string;
  uploadComplete: boolean;

  constructor(public dialog: MatDialog) {}

  onFilesChange(files: File[]): void {
    if (files.length > 1) {
      // this.toast.error('Please only upload one File!');
      return;
    }
    this.uploadComplete = true;
    this.filename = files[0].name;

    // TODO: BE
    // SAVE / UPDATE Video in IHelpItem here

    this.dialog.closeAll();
  }
}
