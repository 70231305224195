import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IHelpItem } from 'src/app/shared/interfaces/IHelpItem';
import { IHelpItemLink } from 'src/app/shared/interfaces/IHelpItemLink';
import { IVideos } from 'src/app/shared/interfaces/IVideos';
import { TenantService } from 'src/app/shared/services/tenant.service';
import { TENANT } from 'src/app/shared/Tenant.enum';
import { HelpItemService } from 'src/app/shared/services/help-item.service';
import { IHelpItemExtended } from 'src/app/shared/interfaces/IHelpItemExtended';
import * as copy from 'copy-to-clipboard';
import { HelpTopicEditorComponent } from '../help-topic-editor/help-topic-editor.component';
import { S3Service } from '../../services/s3.service';

@Component({
  selector: 'app-edit-help-topic',
  templateUrl: './edit-help-topic.component.html',
  styleUrls: ['./edit-help-topic.component.scss']
})
export class EditHelpTopicComponent {
  @Input() set extendedItem(value: IHelpItemExtended) {
    this._extendedItem = value;
    this.selectedTenant = this.extendedItem[TENANT.Siemens] ? TENANT.Siemens : this.extendedItem[TENANT.SHS] ? TENANT.SHS : TENANT.SE;
    this.item = value[this.selectedTenant];
    this.item.addOns.sort((a, b) => a.label.localeCompare(b.label));
  }

  get extendedItem(): IHelpItemExtended {
    return this._extendedItem;
  }

  private _extendedItem: IHelpItemExtended;
  selectedTenant: TENANT = TENANT.Siemens;
  TENANT = TENANT;
  company = this.tenantService.getTenantString();
  item: IHelpItem;
  showJustShared = false;

  constructor(public tenantService: TenantService, public dialog: MatDialog, public s3Service: S3Service, public helpTopicsService: HelpItemService) {}

  async deleteVideosFromS3(videos: IVideos[]): Promise<void> {
    for (const video of videos) {
      if (!video.url) {
        continue;
      }
      await this.s3Service.deleteFileFromS3(video.url);
    }
  }

  async deletePDFsFromS3(links: IHelpItemLink[]): Promise<void> {
    for (const link of links) {
      if (!link.linkUrl) {
        continue;
      }
      await this.s3Service.deleteFileFromS3(link.linkUrl);
    }
  }

  async delete(item: IHelpItem): Promise<void> {
    // Delete all videos from S3
    if (item.videos) {
      await this.deleteVideosFromS3(item.videos);
    }
    // Delete all links from S3
    if (item.links) {
      await this.deletePDFsFromS3(item.links);
    }
    void (await this.helpTopicsService.deleteHelpItem(this.selectedTenant, item.id));
  }

  openItemEditor(): void {
    this.item.tenant = this.selectedTenant;
    this.item.linkId = this.extendedItem.id;
    this.dialog.open(HelpTopicEditorComponent, {
      minWidth: '600px',
      width: '70vw',
      maxHeight: '95vh',
      data: this.item
    });
  }

  tenantSelected(tenant: TENANT): void {
    this.selectedTenant = tenant;
    this.item = this.extendedItem[this.selectedTenant];
  }

  async copyShareLink(): Promise<void> {
    const categoryUrl = await this.helpTopicsService.getCategoryUrl(this.item.category).toPromise();
    this.showJustShared = true;
    // Copies to clipboard
    copy(`${this.tenantService.getBaseUrlByTenant(this.selectedTenant)}${categoryUrl}?id=${this.item.id.substring(9)}`);

    setTimeout(() => {
      this.showJustShared = false;
    }, 2000);
  }
}
