<div class="container">
  <div>
    <a [routerLink]="'/admin'" class="container__back">
      <mat-icon class="icon-display">keyboard_arrow_left</mat-icon>
      {{ 'admin-page.whats-new.buttons.back-button' | translate }}
    </a>
  </div>
  <p>{{ 'admin-page.whats-new.text' | translate }}</p>
  <!-- Loop ALL whatsNew per section -->
  <div *ngFor="let part of sectionParts">
    <h2>
      {{ part.text }}
    </h2>
    <div class="faq-tiles" *ngFor="let item of whatsNewItems">
      <app-edit-whats-new
        *ngIf="part.id === item.siemens?.id || part.id === item.shs?.id || part.id === item.siemensenergy?.id"
        [WhatsNewItemExtended]="item"
        [attr.data-id]="item.id"
      >
      </app-edit-whats-new>
    </div>
  </div>
</div>
