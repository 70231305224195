import { Injectable } from '@angular/core';
import cssVars from 'css-vars-ponyfill';
import { IColors } from '../interfaces/IColor';
import { IAssets } from '../interfaces/IAssets';
import { ASSETS_SAG, ASSETS_SE, ASSETS_SHS, COLORS_SAG, COLORS_SE, COLORS_SHS } from './designSet';
import { TenantService } from './tenant.service';

@Injectable()
export class DesignService {
  constructor(private tenantService: TenantService) {}

  getColorPalette(): IColors {
    if (this.tenantService.isTenantSiemens()) {
      return COLORS_SAG;
    }
    if (this.tenantService.isTenantSHS()) {
      return COLORS_SHS;
    }
    if (this.tenantService.isTenantSE()) {
      return COLORS_SE;
    }

    return COLORS_SAG;
  }

  setColorVariables(colorPalette: IColors): void {
    cssVars({
      variables: {
        ...colorPalette
      }
    });
  }

  /**
   *   Sets every IAssets as a CSS variable and wraps every value it with an "url(...)"
   */
  setAssetsVariables(assets: IAssets): void {
    const assetsUrl = { ...assets };
    Object.keys(assets).map(value => (assetsUrl[value] = `url(${assetsUrl[value]})`));
    cssVars({
      variables: {
        ...assetsUrl
      }
    });
  }

  getAssets(): IAssets {
    if (this.tenantService.isTenantSiemens()) {
      return ASSETS_SAG;
    }
    if (this.tenantService.isTenantSHS()) {
      return ASSETS_SHS;
    }
    if (this.tenantService.isTenantSE()) {
      return ASSETS_SE;
    }

    return ASSETS_SAG;
  }
}
