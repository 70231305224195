<div mat-dialog-content>
  <div class="close-dialogue-icon" mat-icon (click)="closeDialog()">
    <i class="material-icons">close</i>
  </div>

  <h2>{{ 'admin-page.user-management.dialog-title' | translate }}</h2>

  <div class="form">
    <mat-form-field appearance="fill">
      <mat-label>{{ 'admin-page.user-management.firstName' | translate }}</mat-label>
      <input type="text" matInput required [(ngModel)]="firstName" />
      <mat-error *ngIf="!firstName || firstName.trim().length === 0">
        {{ 'admin-page.warning' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>{{ 'admin-page.user-management.lastName' | translate }}</mat-label>
      <input type="text" matInput required [(ngModel)]="lastName" />
      <mat-error *ngIf="!lastName || lastName.trim().length === 0">
        {{ 'admin-page.warning' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>{{ 'admin-page.user-management.gid' | translate }}</mat-label>
      <input type="text" matInput required [(ngModel)]="gid" />
      <mat-error *ngIf="!gid || gid.trim().length === 0">
        {{ 'admin-page.warning' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>{{ 'admin-page.user-management.role' | translate }}</mat-label>
      <mat-select [(ngModel)]="role">
        <mat-option *ngFor="let role of UserRoleEnumKeys" [value]="UserRoleEnum[role]">{{ role }}</mat-option>
      </mat-select>
    </mat-form-field>

    <app-action-button class="save-button" [disabled]="!isSaveEnabled" (click)="onSaveClick()">{{ 'general.save' | translate }}</app-action-button>
  </div>
</div>
