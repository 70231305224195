<div class="container" mat-dialog-content>
  <h2>New translation topic</h2>

  <div class="content">
    <mat-form-field class="search" appearance="fill">
      <mat-label>Topic title</mat-label>
      <input matInput [(ngModel)]="topicTitle" />
    </mat-form-field>
  </div>

  <div class="actions">
    <app-action-button design="stroke" (click)="closeDialog()">Cancel</app-action-button>
    <app-action-button [mat-dialog-close]="topicTitle" [disabled]="!topicTitle || topicTitle.trim().length === 0">Confirm</app-action-button>
  </div>
</div>
