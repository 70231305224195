import { TENANT } from '../Tenant.enum';
import { CategoryEnum } from './ICategory';
import { IHelpItemLinkCountryAddOn } from './IHelpItemCountryAddOn';
import { IHelpItemLink } from './IHelpItemLink';
import { IVideos } from './IVideos';

export class IHelpItem {
  id: string;
  title: string;
  description?: string;
  videos?: IVideos[];
  links?: IHelpItemLink[];
  addOns?: IHelpItemLinkCountryAddOn[];
  category?: CategoryEnum;
  isMostRelevant?: boolean;
  tenant?: TENANT;
  sortOrder?: number;
  sortOrderMostRelevant?: number;
  linkId?: string;
}
