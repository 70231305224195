import { Component, Input } from '@angular/core';
import { IHelpItemCountryAddOnUrl } from '../../interfaces/IHelpItemCountryAddOnUrl';

@Component({
  selector: 'app-country-add-on',
  templateUrl: './country-add-on.component.html',
  styleUrls: ['./country-add-on.component.scss']
})
export class CountryAddOnComponent {
  @Input() countryName: string;
  @Input() countryCode: string;
  @Input() addOnUrls: IHelpItemCountryAddOnUrl[];
  @Input() collapsed = true;
}
