import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CategoryEnum } from 'src/app/shared/interfaces/ICategory';
import { TopicsTileInterface } from 'src/app/shared/interfaces/topics-tile.interface';

@Component({
  selector: 'app-faq-search',
  templateUrl: './faq-search.component.html',
  styleUrl: './faq-search.component.scss'
})
export class FaqSearchComponent {
  @Input() categoryTiles: TopicsTileInterface[];
  @Input() amountOfSearchHits: number;
  @Input() searchValue = '';
  @Input() showTextSearch: boolean;
  @Input() showCategorySearch: boolean;
  @Output() categoryClicked = new EventEmitter<{ category: CategoryEnum; categoryIndex: number }>();
  @Output() searchInput = new EventEmitter<string>();
  @Output() searchCleared = new EventEmitter<boolean>();

  protected clearSearch(): void {
    this.searchValue = '';
    this.searchCleared.emit(true);
  }

  protected startSearch(value: string): void {
    this.searchValue = value;
    this.searchInput.emit(value);
  }

  protected handleCategoryClicked(category: string, categoryIndex: number): void {
    this.categoryClicked.emit({ category: this.getCategoryEnum(category), categoryIndex });
  }

  private getCategoryEnum(value: string): CategoryEnum | undefined {
    for (const key in CategoryEnum) {
      if (CategoryEnum[key as keyof typeof CategoryEnum] === value) {
        return CategoryEnum[key as keyof typeof CategoryEnum];
      }
    }
    return undefined;
  }
}
