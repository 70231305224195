import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class S3Service {
  constructor(private http: HttpClient) {}

  async deleteFileFromS3(url: string): Promise<void> {
    if (url === undefined) {
      return;
    }

    const urlList = url.split('.net/');
    if (urlList.length < 2) {
      return;
    }

    const filename = urlList[1];
    const URL = `${environment.api.backend}documents?key=${filename}`; // TODO

    try {
      await this.http.delete(URL).toPromise();
    } catch (error) {
      console.log(error);
    }
  }
}
