<div class="tenants-container">
  <div class="tenants__box">
    <div class="title">Choose tenant to continue</div>
    <div class="tenants">
      <div class="tenant" (click)="goTo('siemens')">
        <img src="./assets/svgs/logo-siemens.svg" />
      </div>
      <div class="tenant" (click)="goTo('healthineers')">
        <img src="./assets/svgs/logo-healthineers.svg" />
      </div>
      <div class="tenant" (click)="goTo('energy')">
        <img src="./assets/svgs/logo-energy-no-padding.svg" />
      </div>
    </div>
  </div>
</div>
