<div class="container">
  <h2>translations.new.title</h2>

  <div class="content">
    <mat-form-field class="search" appearance="fill">
      <mat-label>translations.new.item-key</mat-label>
      <input matInput [(ngModel)]="itemKey" />
    </mat-form-field>
    <mat-form-field class="search" appearance="fill">
      <mat-label>translations.new.item-value</mat-label>
      <input matInput [(ngModel)]="itemValue" />
    </mat-form-field>
  </div>

  <div class="actions">
    <app-action-button design="stroke" (click)="closeDialog()">translations.new.cancel</app-action-button>
    <app-action-button [mat-dialog-close]="{ key: itemKey, value: itemValue }" [disabled]="!itemKey || itemKey.trim().length === 0">Confirm</app-action-button>
  </div>
</div>
