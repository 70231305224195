import { APP_INITIALIZER, ApplicationRef, DoBootstrap, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { NgxsModule } from '@ngxs/store';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { TRACKING_CONFIG, TrackingSuiteModule } from '@siemens/tracking-suite-angular-lib';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { AuthGmsAngularClientModule } from './auth/auth.module';
import { AppState } from './auth/store';
import { httpInterceptorProviders } from './auth/http-interceptors';
import { HelpTopicsComponent } from './help-topics/help-topics.component';
import { FaqComponent } from './faq/faq.component';
import { MostRelevantComponent } from './most-relevant/most-relevant.component';
import { NewsComponent } from './news/news.component';
import { DesignService } from './shared/services/design.service';
import { QuestionComponent } from './faq/question/question.component';
import { TopicsTileComponent } from './shared/components/topic-tiles-container/topics-tile/topics-tile.component';
import { CriticalInfoComponent } from './critical-info/critical-info.component';
import { HelpTopicsSubpageComponent } from './help-topics/help-topics-subpage/help-topics-subpage.component';
import { FaqAllComponent } from './faq/faq-all/faq-all.component';
import { MostViewedItemComponent } from './most-relevant/most-relevant-item/most-relevant-item.component';
import { VideoplayerComponent } from './shared/components/videoplayer/videoplayer.component';
import { CountryAddOnComponent } from './shared/components/country-add-on/country-add-on.component';
import { AdminComponent } from './admin/admin.component';
import { CmsFaqsComponent } from './admin/cms-faqs/cms-faqs.component';
import { HeaderComponent } from './header/header.component';
import { EditFaqComponent } from './admin/cms-faqs/edit-faq/edit-faq.component';
import { AddFaqComponent } from './admin/cms-faqs/add-faq/add-faq.component';
import { EditWhatsNewComponent } from './admin/cms-whats-new/edit-whats-new/edit-whats-new.component';
import { EditSupportContactComponent } from './admin/cms-support-contacts/edit-support-contacts/edit-support-contacts.component';
import { AddWhatsNewComponent } from './admin/cms-whats-new/add-whats-new/add-whats-new.component';
import { AddSupportContactComponent } from './admin/cms-support-contacts/add-support-contacts/add-support-contacts.component';
import { AddRegionComponent } from './admin/cms-support-contacts/add-region/add-region.component';
import { SupportDialogComponent } from './support-dialog/support-dialog.component';
import { TranslationsComponent } from './admin/translations/translations.component';
import { AccordionComponent } from './shared/components/accordion/accordion.component';
import { ActionButtonComponent } from './shared/components/action-button/action-button.component';
import { TranslationNewItemDialogComponent } from './admin/translations/translation-new-item-dialog/translation-new-item-dialog.component';
import { TranslationAccordionComponent } from './admin/translations/translation-accordion/translation-accordion.component';
import { TranslationNewTopicDialogComponent } from './admin/translations/translation-new-topic-dialog/translation-new-topic-dialog.component';
import { CmsHelpTopicsComponent } from './admin/cms-help-topics/cms-help-topics.component';
import { EditHelpTopicComponent } from './admin/cms-help-topics/edit-help-topic/edit-help-topic.component';
import { HelpTopicEditorComponent } from './admin/cms-help-topics/help-topic-editor/help-topic-editor.component';
import { FileUploaderComponent } from './shared/components/file-uploader/file-uploader.component';
import { UploadVideoDialogueComponent } from './admin/cms-help-topics/upload-video-dialogue/upload-video-dialogue.component';
import { TenantIndependentComponent } from './tenant-independent/tenant-independent.component';
import { UserManagementComponent } from './admin/user-management/user-management.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { AddNewUserDialogComponent } from './admin/user-management/add-new-user-dialog/add-new-user-dialog.component';
import { HelpItemService } from './shared/services/help-item.service';
import { FaqService } from './shared/services/faq.service';
import { WhatsNewService } from './shared/services/whats-new.service';
import { SupportContactsService } from './shared/services/support-contacts.service';
import { LoadingScreenComponent } from './shared/components/loading-screen/loading-screen.component';
import { LoadingIndicatorComponent } from './shared/components/loading-indicator/loading-indicator.component';
import { CmsWhatsNewComponent } from './admin/cms-whats-new/cms-whats-new.component';
import { CmsSupportContactsComponent } from './admin/cms-support-contacts/cms-support-contacts.component';
import { FooterComponent } from './footer/footer.component';
import { AuthService } from './auth/services/auth.service';
import { UserService } from './shared/services/user.service';
import { TopicTilesContainerComponent } from './shared/components/topic-tiles-container/topic-tiles-container.component';
import { FaqSearchComponent } from './faq/faq-search/faq-search.component';
import { FaqListComponent } from './faq/faq-list/faq-list.component';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, environment.languageAssetsPath, '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HelpTopicsComponent,
    FaqComponent,
    FaqAllComponent,
    MostRelevantComponent,
    ContactComponent,
    NewsComponent,
    QuestionComponent,
    TopicTilesContainerComponent,
    TopicsTileComponent,
    CriticalInfoComponent,
    HelpTopicsSubpageComponent,
    FaqAllComponent,
    MostViewedItemComponent,
    VideoplayerComponent,
    CountryAddOnComponent,
    AdminComponent,
    CmsFaqsComponent,
    HeaderComponent,
    EditFaqComponent,
    AddFaqComponent,
    CmsWhatsNewComponent,
    AddWhatsNewComponent,
    EditWhatsNewComponent,
    AddSupportContactComponent,
    EditSupportContactComponent,
    AddRegionComponent,
    HeaderComponent,
    SupportDialogComponent,
    TranslationsComponent,
    AccordionComponent,
    ActionButtonComponent,
    TranslationNewItemDialogComponent,
    TranslationAccordionComponent,
    TranslationNewTopicDialogComponent,
    CmsHelpTopicsComponent,
    EditHelpTopicComponent,
    HelpTopicEditorComponent,
    FileUploaderComponent,
    UploadVideoDialogueComponent,
    TenantIndependentComponent,
    UserManagementComponent,
    UnauthorizedComponent,
    AddNewUserDialogComponent,
    LoadingScreenComponent,
    LoadingIndicatorComponent,
    CmsWhatsNewComponent,
    CmsSupportContactsComponent,
    FooterComponent,
    FaqSearchComponent,
    FaqListComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    AuthGmsAngularClientModule,
    NgxsModule.forRoot(AppState, { developmentMode: !environment.production }),
    HttpClientModule,
    ToastrModule.forRoot(),
    MatIconModule,
    MatFormFieldModule,
    MatDialogModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    CommonModule,
    FormsModule,
    MatButtonToggleModule,
    MatExpansionModule,
    MatSlideToggleModule,
    AngularEditorModule,
    TrackingSuiteModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [AuthService],
      multi: true
    },
    {
      provide: TRACKING_CONFIG,
      useValue: {
        // apiKey: environment.newTracking.apiKey,
        apiKey: (() => {
          const origin: string = window.location.origin;
          if (origin.includes('energy')) {
            return environment.newTracking.apiKeySE;
          } else if (origin.includes('healthineers')) {
            return environment.newTracking.apiKeySHS;
          } else {
            return environment.newTracking.apiKey;
          }
        })(),
        //  apiVersion: 'v2', // optional, default = 'v2'
        endpointStage: environment.newTracking.endpointStage
      }
    },
    UnauthorizedComponent,
    httpInterceptorProviders,
    DesignService,
    HelpItemService,
    FaqService,
    WhatsNewService,
    SupportContactsService,
    UserService
  ],
  bootstrap: [AppComponent]
})
export class AppModule implements DoBootstrap {
  constructor(private authService: AuthService) {}

  public ngDoBootstrap(appRef: ApplicationRef): void {
    this.authService.isAuthenticated$
      .pipe(
        filter(auth => !!auth),
        tap(() => appRef.bootstrap(AppComponent))
      )
      .subscribe();
  }
}

function initializeAppFactory(authService: AuthService): () => Observable<boolean> {
  return () =>
    authService.isAuthenticated$.pipe(
      filter(auth => !auth),
      tap(() => authService.login())
    );
}
