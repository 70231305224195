<div class="container">
  <h1>
    <a class="back-button" [routerLink]="'/home'">
      <mat-icon class="icon-display">keyboard_arrow_left</mat-icon>
    </a>
    {{ 'faq.all.title' | translate }}
  </h1>

  <app-faq-list [showTextSearch]="true" [showCategorySearch]="true"></app-faq-list>
</div>
