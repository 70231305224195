import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { LoadingScreenComponent } from '../components/loading-screen/loading-screen.component';

@Injectable({
  providedIn: 'root'
})
export class LoadingService implements OnDestroy {
  loadingScreen: any;
  shouldLoad$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private unsubscribe$ = new Subject<void>();
  private abortDismiss$ = new Subject<void>();

  constructor(private dialog: MatDialog) {
    this.subscribeToPresent();
    this.subscribeToDismiss();
    this.abortDismiss$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.subscribeToDismiss();
    });
  }

  present(): void {
    this.shouldLoad$.next(true);
    this.abortDismiss$.next();
  }

  dismiss(): void {
    this.shouldLoad$.next(false);
  }

  private subscribeToPresent(): void {
    this.shouldLoad$.pipe(takeUntil(this.unsubscribe$)).subscribe(shouldLoad => {
      if (shouldLoad && !this.loadingScreen) {
        this.loadingScreen = this.dialog.open(LoadingScreenComponent, {
          minWidth: '100vw',
          minHeight: '100vh',
          disableClose: true,
          panelClass: 'transparent-dialog-panel'
        });
      }
    });
  }

  private subscribeToDismiss(): void {
    this.shouldLoad$.pipe(debounceTime(100), takeUntil(this.abortDismiss$), takeUntil(this.unsubscribe$)).subscribe(shouldLoad => {
      if (!shouldLoad && this.loadingScreen) {
        this.loadingScreen.close();
        this.loadingScreen = null;
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.abortDismiss$.complete();
  }
}
