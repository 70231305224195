import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TenantService } from '../shared/services/tenant.service';
import { SupportContactsService } from '../shared/services/support-contacts.service';
import { LoadingService } from '../shared/services/loading.service';
import { CountryEnum } from '../shared/enums/CountryEnum';
import { ISupportContactExtended, ISupportContactItem } from './../shared/interfaces/ISupportContact';
interface RegionInterface {
  region: string;
  contacts: SupportContactInterface[];
}
interface SupportContactInterface {
  country: string;
  flagUrl: string;
  organization: string;
  contact: string;
}

@Component({
  selector: 'app-support-dialog',
  templateUrl: './support-dialog.component.html',
  styleUrls: ['./support-dialog.component.scss']
})
export class SupportDialogComponent implements OnInit {
  regionSelected = '';
  searchValue = '';
  isSearching = false;
  supportContactItems: ISupportContactExtended[];
  contactRegions: RegionInterface[] = [];
  supportContent: string;

  constructor(
    public tenantService: TenantService,
    private supportContactsService: SupportContactsService,
    public dialogRef: MatDialogRef<SupportDialogComponent>,
    private loadingService: LoadingService
  ) {}

  async ngOnInit(): Promise<void> {
    this.loadingService.present();
    this.supportContactItems = await this.supportContactsService.getAllForCMS();
    this.retrieveData();
    this.loadingService.dismiss();
  }

  retrieveData(): void {
    this.supportContactItems.forEach(item => {
      if (this.tenantService.isTenantSiemens() && item.siemens !== undefined) {
        this.mapItemsInRegions(item.siemens);
      } else if (this.tenantService.isTenantSHS() && item.shs !== undefined) {
        this.mapItemsInRegions(item.shs);
      } else if (this.tenantService.isTenantSE() && item.siemensenergy !== undefined) {
        this.mapItemsInRegions(item.siemensenergy);
      }
    });
  }

  mapItemsInRegions(itemT: ISupportContactItem): void {
    if (itemT.id !== 'contact|text') {
      const countryCode: string = Object.keys(CountryEnum)[Object.values(CountryEnum).indexOf(itemT.country)];
      const flagUrl: string = 'assets/svgs/flags/' + (countryCode ? countryCode.toLowerCase() : 'globe') + '.svg';

      if (this.contactRegions.find(s => s.region === itemT.region)) {
        this.contactRegions
          .find(s => s.region === itemT.region)
          .contacts.push({
            country: itemT.country,
            flagUrl,
            organization: itemT.organization,
            contact: itemT.contact
          });
      } else {
        this.contactRegions.push({
          region: itemT.region,
          contacts: [
            {
              country: itemT.country,
              flagUrl,
              organization: itemT.organization,
              contact: itemT.contact
            }
          ]
        });
      }
    } else if (itemT.id === 'contact|text') {
      this.supportContent = itemT.contact;
    }
  }
  displayedContacts: SupportContactInterface[] = [];

  toggleRegion(region: string): void {
    this.isSearching = false;
    this.searchValue = '';

    if (this.regionSelected !== region) {
      this.regionSelected = region;

      for (const contactRegion of this.contactRegions) {
        if (contactRegion.region === this.regionSelected) {
          this.displayedContacts = contactRegion.contacts;
          this.displayedContacts.sort((a, b) => a.country.localeCompare(b.country));
          break;
        }
      }
    } else {
      this.regionSelected = '';
      this.displayedContacts = [];
    }
  }

  onSearch(value: string): void {
    if (!value) {
      return;
    }
    const searchValue = value.toLowerCase().trim();

    this.isSearching = true;
    this.regionSelected = '';
    const result: SupportContactInterface[] = [];

    for (const region of this.contactRegions) {
      for (const contact of region.contacts) {
        if (contact.country.toLowerCase().includes(searchValue) || contact.contact.toLowerCase().includes(searchValue)) {
          result.push(contact);
        }
      }
    }

    this.displayedContacts = result;
  }

  clearSearch(): void {
    this.searchValue = '';
    this.isSearching = false;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
