import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { IHelpItem } from 'src/app/shared/interfaces/IHelpItem';
import { IHelpItemLinkCountryAddOn } from 'src/app/shared/interfaces/IHelpItemCountryAddOn';
import { IHelpItemLink } from 'src/app/shared/interfaces/IHelpItemLink';
import { IVideos } from 'src/app/shared/interfaces/IVideos';
import { TenantService } from 'src/app/shared/services/tenant.service';
import { environment } from 'src/environments/environment';
import { v4 as uuid } from 'uuid';
import { map } from 'rxjs/operators';
import { TENANT } from 'src/app/shared/Tenant.enum';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { HelpItemService } from '../../../shared/services/help-item.service';
import { CountryEnum } from './../../../shared/enums/CountryEnum';
import { IHelpItemCountryAddOnUrl } from './../../../shared/interfaces/IHelpItemCountryAddOnUrl';

interface FileUploadStatus {
  uploadComplete: boolean;
  filename?: string;
}

@Component({
  selector: 'app-add-help-topic',
  templateUrl: './help-topic-editor.component.html',
  styleUrls: ['./help-topic-editor.component.scss']
})
export class HelpTopicEditorComponent implements OnInit {
  TENANT = TENANT;
  tenantValues = Object.values(TENANT);
  helpItem: IHelpItem;
  editorConfig: AngularEditorConfig = {
    minHeight: '4rem',
    editable: true,
    sanitize: true,
    toolbarHiddenButtons: [
      ['strikeThrough', 'justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'heading', 'fontName', 'subscript', 'superscript'],
      ['fontSize', 'textColor', 'backgroundColor', 'customClasses', 'insertHorizontalRule', 'removeFormat', 'toggleEditorMode', 'insertImage', 'insertVideo']
    ]
  };

  isNewHelpItem = true;

  helpItemCountriesKeys = Object.keys(CountryEnum);
  HelpItemCountryEnum = CountryEnum;

  videoUploadStatus: FileUploadStatus[] = [];
  linkUploadStatus: FileUploadStatus[] = [];
  // We store all the S3 urls, that we are going to delete or update once saving the changes
  urlsToDelete: string[] = [];
  urlsToUpdate: string[] = [];

  company = this.tenantService.getTenantString();

  categories?: string[];
  selectedTenant: TENANT = TENANT.Siemens;

  get isSaveEnabled(): boolean {
    const titleValid = this.helpItem.title !== undefined && this.helpItem.title.trim().length > 0;
    return titleValid && this.helpItem.category !== undefined && this.isVideoInputValid && this.isLinkInputValid && this.isAddOnInputValid;
  }

  get isVideoInputValid(): boolean {
    for (const video of this.helpItem.videos) {
      const urlValid = video.url !== undefined && video.url.trim().length > 0;
      const labelValid = video.label !== undefined && video.label.trim().length > 0;
      const titleValid = video.title !== undefined && video.title.trim().length > 0;
      if ((!urlValid && !labelValid) || !titleValid) {
        return false;
      }
    }
    return true;
  }

  get isLinkInputValid(): boolean {
    for (const link of this.helpItem.links) {
      const urlValid = link.linkUrl !== undefined && link.linkUrl.trim().length > 0;
      const labelValid = link.label !== undefined && link.label.trim().length > 0;
      if (!urlValid || !labelValid) {
        return false;
      }
    }
    return true;
  }

  get isAddOnInputValid(): boolean {
    for (const addOn of this.helpItem.addOns) {
      const countryValid = addOn.country !== undefined;
      let areAllUrlsValid = false;
      if (addOn.addOnUrls !== undefined) {
        const urlValid = [...addOn.addOnUrls.map(this.isUrlValid)];
        areAllUrlsValid = !urlValid.includes(false);
      }
      const labelValid = addOn.label !== undefined && addOn.label.trim().length > 0;
      if (!countryValid || !areAllUrlsValid || !labelValid) {
        return false;
      }
    }
    return true;
  }

  isUrlValid(url: IHelpItemCountryAddOnUrl): boolean {
    return url.addOnUrl !== undefined && url.addOnUrl.trim().length > 0;
  }

  constructor(
    private tenantService: TenantService,
    public dialog: MatDialog,
    private http: HttpClient,
    private toast: ToastrService,
    private helpItemService: HelpItemService,
    public dialogRef: MatDialogRef<HelpTopicEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data?: IHelpItem
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.isNewHelpItem = false;
      // Copy the object, so that we don't edit the original data
      this.helpItem = JSON.parse(JSON.stringify(this.data));

      this.videoUploadStatus = Array.from({ length: this.helpItem.videos.length }, () => {
        return { uploadComplete: true };
      });

      this.linkUploadStatus = Array.from({ length: this.helpItem.links.length }, () => {
        return { uploadComplete: true };
      });
    } else {
      this.helpItem = new IHelpItem();
      this.helpItem.videos = [];
      this.helpItem.addOns = [];
      this.helpItem.links = [];
      this.helpItem.isMostRelevant = false;
    }

    // Get the different help topic categories available
    this.helpItemService
      .fetchHelpTiles()
      .pipe(map(tiles => tiles.map(tile => tile.title)))
      .subscribe(categories => (this.categories = categories));
  }

  closeAddHelpTopicDialogue(): void {
    this.dialog.closeAll();
  }

  async saveToDBs(helpItem: IHelpItem): Promise<void> {
    try {
      if (this.isNewHelpItem) {
        await this.helpItemService.saveNewHelpItem(this.helpItem.tenant, helpItem);
        this.toast.success('Added item to database.');
      } else {
        await this.helpItemService.updateHelpItem(this.helpItem.tenant, helpItem);
        this.toast.success('Successfully edited item.');
      }

      this.dialog.closeAll();
      const url = `${this.tenantService.getBaseUrlByTenant(this.selectedTenant)}/admin/help-topics?id=${this.data.linkId.substring(9)}`;
      window.location.href = url;
    } catch (error) {
      console.log(error);
    }
  }

  onFilesChange(files: File[], index: number, isVideo: boolean): void {
    if (files.length > 1) {
      // this.toast.error('Please only upload one File!');
      return;
    }

    let type = '';
    let status: FileUploadStatus;
    if (isVideo) {
      type = 'video';
      status = this.videoUploadStatus[index];
    } else {
      type = 'pdf';
      status = this.linkUploadStatus[index];
    }

    status.uploadComplete = true;

    const fileToUpload = new File([files[0]], `${uuid()}_${files[0].name}`, {
      type: files[0].type,
      lastModified: files[0].lastModified
    });
    status.filename = fileToUpload.name;

    this.http
      .get<{ link: string }>(`${environment.api.backend}documents/upload-link`, {
        params: {
          stage: environment.stage,
          company: this.company,
          type,
          documentName: status.filename
        }
      })
      .subscribe(presignedUrl => {
        try {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          this.http.put(presignedUrl.link, fileToUpload).toPromise();
        } catch (error) {
          console.log(error);
        }
        this.toast.success('Upload success');
        console.log('upload sucess');
        // this.invalidation();
      });
    if (isVideo) {
      this.helpItem.videos[index].url = `${environment.api.documents}${environment.stage}/${this.company}/video/${fileToUpload.name}`;
    } else {
      this.helpItem.links[index].linkUrl = `${environment.api.documents}${environment.stage}/${this.company}/pdf/${fileToUpload.name}`;
    }
  }

  async onSaveClick(): Promise<void> {
    // ms TODO: test and fix this and put back in
    // for (const url of this.urlsToDelete) {
    //   await this.s3service.deleteFileFromS3(url);
    // }
    await this.saveToDBs(this.helpItem);
  }

  addVideo(): void {
    this.helpItem.videos.push(new IVideos());
    this.videoUploadStatus.push({ uploadComplete: false });
  }

  addCountryAddOn(): void {
    this.helpItem.addOns.push(new IHelpItemLinkCountryAddOn());
  }

  addCountryAddOnUrl(index: number): void {
    if (this.helpItem.addOns[index].addOnUrls) {
      this.helpItem.addOns[index].addOnUrls.push(new IHelpItemCountryAddOnUrl());
    } else {
      this.helpItem.addOns[index].addOnUrls = [];
      this.helpItem.addOns[index].addOnUrls.push(new IHelpItemCountryAddOnUrl());
    }
  }

  deleteCountryAddOnUrl(index: number, urlIndex: number): void {
    this.helpItem.addOns[index].addOnUrls.splice(urlIndex, 1);
  }

  addLink(): void {
    this.helpItem.links.push(new IHelpItemLink());
    this.linkUploadStatus.push({ uploadComplete: false });
  }

  deleteCountryAddOn(index: number): void {
    this.helpItem.addOns.splice(index, 1);
  }

  updateLink(index: number): void {
    if (this.linkUploadStatus[index].uploadComplete) {
      this.linkUploadStatus[index].uploadComplete = false;
      this.urlsToUpdate.push(this.helpItem.links[index].linkUrl);
    }
  }

  deleteLink(index: number): void {
    if (this.linkUploadStatus[index].uploadComplete) {
      this.urlsToDelete.push(this.helpItem.links[index].linkUrl);
    }
    this.helpItem.links.splice(index, 1);
    this.linkUploadStatus.splice(index, 1);
  }

  deleteVideo(index: number): void {
    if (this.linkUploadStatus[index].uploadComplete) {
      this.urlsToDelete.push(this.helpItem.videos[index].url);
    }
    this.helpItem.videos.splice(index, 1);
    this.videoUploadStatus.splice(index, 1);
  }
}
