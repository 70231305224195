import { Injectable } from '@angular/core';
import { TENANT } from '../Tenant.enum';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TenantService {
  getTenant(): TENANT {
    const origin = window.location.origin;

    if (origin.includes('energy')) {
      return TENANT.SE;
    }
    if (origin.includes('healthineers')) {
      return TENANT.SHS;
    }

    return TENANT.Siemens; // default
  }

  isTenantSiemens(): boolean {
    return this.getTenant() === TENANT.Siemens;
  }

  isTenantSE(): boolean {
    return this.getTenant() === TENANT.SE;
  }

  isTenantSHS(): boolean {
    return this.getTenant() === TENANT.SHS;
  }

  getTenantString(): string {
    if (this.isTenantSHS()) {
      return TENANT.SHS;
    }
    if (this.isTenantSE()) {
      return TENANT.SE;
    }
    if (this.isTenantSiemens()) {
      return TENANT.Siemens;
    }
    throw new console.error('Could not retrieve tenant string.');
  }

  getBaseUrlByTenant(tenant: TENANT): string {
    switch (environment.stage) {
      case 'prod':
        switch (tenant) {
          case TENANT.SE:
            return 'https://energy.workday-help.siemens.cloud';
          case TENANT.SHS:
            return 'https://healthineers.workday-help.siemens.cloud';
          default:
            return 'https://workday-help.siemens.cloud';
        }
      case 'dev':
        switch (tenant) {
          case TENANT.SE:
            return 'https://dev.energy.workday-help.siemens.cloud';
          case TENANT.SHS:
            return 'https://dev.healthineers.workday-help.siemens.cloud';
          default:
            return 'https://dev.workday-help.siemens.cloud';
        }
      case 'local':
        return 'http://localhost:4200';
      default:
        return 'https://workday-help.siemens.cloud';
    }
  }
}
