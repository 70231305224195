import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IHelpItemExtended } from 'src/app/shared/interfaces/IHelpItemExtended';
import { CategoryEnum } from 'src/app/shared/interfaces/ICategory';
import { HelpItemService } from 'src/app/shared/services/help-item.service';
import { TenantService } from 'src/app/shared/services/tenant.service';
import { TENANT } from 'src/app/shared/Tenant.enum';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LoadingService } from '../../shared/services/loading.service';
import { HelpTopicEditorComponent } from './help-topic-editor/help-topic-editor.component';

@Component({
  selector: 'app-cms-help-topics',
  templateUrl: './cms-help-topics.component.html',
  styleUrls: ['./cms-help-topics.component.scss']
})
export class CmsHelpTopicsComponent implements OnInit, OnDestroy {
  company = this.tenantService.getTenantString();
  TENANT = TENANT;
  @Input() category: any;
  categoryKeys = Object.keys(CategoryEnum);
  helpItems: IHelpItemExtended[];
  itemsPerCategory: IHelpItemExtended[];
  private destroy$ = new Subject<void>();

  constructor(
    public dialog: MatDialog,
    private helpItemService: HelpItemService,
    private tenantService: TenantService,
    private loadingService: LoadingService,
    private toast: ToastrService,
    private route: ActivatedRoute
  ) {}

  async ngOnInit(): Promise<void> {
    this.loadingService.present();
    this.helpItems = await this.helpItemService.getAllForCMS();
    this.helpItems.forEach(key => {
      if (key !== undefined) {
        const hItem = key;
        key.id = hItem.siemens ? hItem.siemens.id : hItem.siemensenergy ? hItem.siemensenergy.id : hItem.shs.id;
      }
    });
    this.loadingService.dismiss();
    // Once we have all the items, if the id query parameter was passed, we directly scroll to that item.
    this.getHighlightedTopicId();
  }

  sortOrder(): void {
    this.itemsPerCategory.forEach(key => {
      if (key !== undefined) {
        const hItem = key;
        key.sortOrder = hItem.siemens ? hItem.siemens.sortOrder : hItem.siemensenergy ? hItem.siemensenergy.sortOrder : hItem.shs.sortOrder;
        key.id = hItem.siemens ? hItem.siemens.id : hItem.siemensenergy ? hItem.siemensenergy.id : hItem.shs.id;
      }
    });
    this.itemsPerCategory.sort((a, b) => a.sortOrder - b.sortOrder);
  }

  openNewFaqDialogue(): void {
    this.dialog.open(HelpTopicEditorComponent, {
      minWidth: '600px',
      width: '70vw',
      maxHeight: '95vh'
    });
  }

  getQuestionsByCategory(category: string): IHelpItemExtended[] {
    if (this.helpItems) {
      this.itemsPerCategory = this.helpItems.filter(
        question =>
          question.siemens?.category === CategoryEnum[category] || question.shs?.category === CategoryEnum[category] || question.siemensenergy?.category === CategoryEnum[category]
      );
      this.sortOrder();
    }
    return this.itemsPerCategory;
  }

  getCategoryValue(key: string): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return CategoryEnum[key];
  }

  private getHighlightedTopicId() {
    this.route.queryParams
      .pipe(
        filter(params => params.id !== undefined),
        takeUntil(this.destroy$)
      )
      .subscribe(params => {
        const highlightedId: string = params.id;

        const highlightedItem = this.helpItems.find(item => item.id === 'helpItem|' + highlightedId);

        if (!highlightedItem) {
          this.toast.warning('Could not find help topic with specified id.');
          console.log('Could not find help topic with specified id.');
          return;
        }

        setTimeout(() => {
          // Timeout in order to wait until the page has fully loaded before querying the html element
          const element: HTMLElement = document.querySelector('app-edit-help-topic[data-id="' + highlightedItem.id + '"] app-accordion > div');

          if (element) {
            // Open up the highlighted element
            (element.firstElementChild as HTMLElement).click();
            setTimeout(() => {
              element.scrollIntoView();
            }, 1000);
          }
        }, 0);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
