export const environment = {
  production: false,
  stage: 'dev',
  languageAssetsPath: 'assets/translations/',
  api: {
    backend: 'https://api.dev.workday-help.siemens.cloud/v1/',
    documents: 'https://assets.dev.workday-help.siemens.cloud/'
  },
  authentication: {
    authWellknownEndpointUrl: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_3plO1v5sg/.well-known/openid-configuration',
    authority: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_3plO1v5sg',
    unauthorizedRoute: '/unauthorized',
    redirectUrl: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    // clientId: 'takgkultnfg7885jtgt601esm', // to work with local BE
    clientId: '2km89rr6g9l2h29mishlibe11a', // to work with DEV BE
    scope: 'openid profile email',
    responseType: 'code',
    silentRenew: true,
    autoUserInfo: true,
    useRefreshToken: true,
    logLevel: 2,
    renewTimeBeforeTokenExpiresInSeconds: 300,
    renewUserInfoAfterTokenRenew: true,
    ignoreNonceAfterRefresh: true
    // secret: 'unused',
    // tokens: 'id_token token',
    // scheme: 'saml2',
    // provider: 'siemens',
    // appID: '1c3ea06f3e7048ccb0263bf99b14b258',
    // clientId: 'fdce312d7be948b39d12d717996729bf',
    // baseUrl: 'https://auth-gms-app.siemens.cloud/1c3ea06f3e7048ccb0263bf99b14b258',
    // customerCallbackUrl: `${window.location.origin}/auth/callback`
  },
  newTracking: {
    endpointStage: 'prod',
    apiKey: 'tjXSIFNts3vppzVrXe0R1bO02Q7bGIa9BShDAGIc',
    apiKeySE: 'y3WKwroOpe1SnhRaBCJDeauINdva1XYX7IKE5LHV',
    apiKeySHS: 'Dmff22pD1R9JsfSqoAXQctrNGBM91MP4lHCUlQ9c'
  }
};
