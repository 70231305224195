import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { UserRoleEnum } from '../../../shared/enums/UserRoleEnum';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-add-new-user-dialog',
  templateUrl: './add-new-user-dialog.component.html',
  styleUrls: ['./add-new-user-dialog.component.scss']
})
export class AddNewUserDialogComponent {
  gid?: string;
  role?: UserRoleEnum;
  firstName?: string;
  lastName?: string;

  UserRoleEnumKeys = Object.keys(UserRoleEnum);
  UserRoleEnum = UserRoleEnum;

  get isSaveEnabled(): boolean {
    return this.gid !== undefined && this.gid.trim().length > 0 && this.role !== undefined;
  }

  constructor(public dialogRef: MatDialogRef<AddNewUserDialogComponent>, public dialog: MatDialog, private http: HttpClient, private toast: ToastrService) {}

  closeDialog(): void {
    this.dialogRef.close();
  }

  onSaveClick(): void {
    this.http
      .post(`${environment.api.backend}user`, {
        gid: this.gid.toUpperCase(),
        role: this.role,
        firstName: this.firstName,
        lastName: this.lastName
      })
      .subscribe(
        () => {
          this.toast.success('Successfully saved user.');
          this.dialogRef.close(true);
        },
        () => {
          this.toast.success('An error occurred...');
        }
      );
  }
}
