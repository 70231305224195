import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserRoleEnum } from '../enums/UserRoleEnum';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http: HttpClient) {}

  getUserRole(gid: string): Observable<UserRoleEnum> {
    return this.http.get(`${environment.api.backend}user?gid=${gid.toUpperCase()}`, { responseType: 'text' }).pipe(
      map((role: string) => {
        if (Object.values(UserRoleEnum).includes(role as UserRoleEnum)) {
          return role as UserRoleEnum;
        } else {
          throw new Error(`Unexpected user role: ${role}`);
        }
      })
    );
  }
}
