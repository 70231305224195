/* eslint-disable max-classes-per-file */

// Load User from Service
export class LoadUserRole {
  static readonly type = '[User] Load User Role';
  constructor(public gid: string) {}
}

export class GetAuthUser {
  static readonly type = '[User] Load Authentication Data Of User';
}

export class LoadUserFail {
  static readonly type = '[User] Load User Fail';
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  constructor(public readonly payload?: any) {}
}
