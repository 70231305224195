import { NgModule } from '@angular/core';
import { AuthModule } from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [],
  exports: [],
  imports: [
    AuthModule.forRoot({
      config: environment.authentication
    })
  ]
})
export class AuthGmsAngularClientModule {}
