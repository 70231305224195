import { Injectable, InjectionToken } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { AuthGmsClientConfig } from '../models/auth-gms-client.config';

export const AuthGmsClientConfigService = new InjectionToken<AuthGmsClientConfig>('AuthGmsClientConfig');

export const ID_TOKEN_KEY = 'id_token';
export const ACCESS_TOKEN_KEY = 'access_token';
export const EXP_HINT = 'expires_at';
export const NONCE_KEY = 'nonce';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public isAuthenticated$: Observable<boolean>;

  constructor(private oidcSecurityService: OidcSecurityService, private store: Store) {
    this.isAuthenticated$ = this.oidcSecurityService.checkAuth().pipe(map(auth => auth.isAuthenticated));
  }

  public login(): void {
    const url = window.location.pathname + window.location.search;
    window.localStorage.setItem('redirectUrl', url);
    this.oidcSecurityService.authorize();
  }

  public logOff(): void {
    this.oidcSecurityService.logoff();
  }

  public getIdToken(): Observable<string> {
    return this.oidcSecurityService.getIdToken();
  }

  public getAccessToken(): Observable<string> {
    return this.oidcSecurityService.getAccessToken();
  }

  public getAuthInfo(): Observable<{ isAuthenticated: boolean; userData; accessToken: string; idToken: string }> {
    return this.oidcSecurityService.checkAuth();
  }
}
