import { Component, OnInit, ChangeDetectorRef, AfterContentChecked, OnDestroy, Input, inject } from '@angular/core';
import { CategoryEnum } from 'src/app/shared/interfaces/ICategory';
import { IQuestion } from 'src/app/shared/interfaces/IQuestion';
import { TenantService } from 'src/app/shared/services/tenant.service';
import { TopicsTileInterface } from 'src/app/shared/interfaces/topics-tile.interface';
import { HelpItemService } from 'src/app/shared/services/help-item.service';
import { filter, mergeMap, Observable, Subject, takeUntil, tap, toArray } from 'rxjs';
import { UserRoleEnum } from 'src/app/shared/enums/UserRoleEnum';
import { Store } from '@ngxs/store';
import { UserState } from 'src/app/auth/store';
import { LoadingService } from '../../shared/services/loading.service';
import { FaqService } from '../../shared/services/faq.service';

@Component({
  selector: 'app-faq-list',
  templateUrl: './faq-list.component.html',
  styleUrl: './faq-list.component.scss'
})
export class FaqListComponent implements OnInit, AfterContentChecked, OnDestroy {
  protected userRole$: Observable<UserRoleEnum> = inject(Store).select(UserState.role);
  @Input() showTextSearch: boolean;
  @Input() showCategorySearch: boolean;
  @Input() defaultCategoryIfLimit?: CategoryEnum;
  @Input() questionLimitIfNoSearch?: number;

  protected userRoleEnum = UserRoleEnum;
  protected categories: boolean[];
  protected selectedCategories: CategoryEnum[] = [];
  protected anyCategoryTrue = false;
  protected keys: string[];
  protected tiles: TopicsTileInterface[] = [];
  protected questions: IQuestion[];
  protected allTrueInitially = true;
  protected searchValue = '';
  protected searchResult: IQuestion[];
  protected searchExceededMessage = '';
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private tenantService: TenantService,
    private faqService: FaqService,
    private changeDetector: ChangeDetectorRef,
    private loadingService: LoadingService,
    private helpTopicsService: HelpItemService
  ) {}

  ngOnInit(): void {
    this.loadingService.present();

    void this.faqService.getAllFaqs(this.tenantService.getTenant().toLowerCase()).subscribe(res => {
      this.loadingService.dismiss();
      this.questions = res;
      this.getCategories();
      this.getHelpTopicsForCategories();
    });
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
    this.getCategoriesBySearch();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  protected getQuestionsByCategory(category: string): IQuestion[] {
    if (!this.searchResult || this.searchResult.length === 0 || this.searchValue === '') {
      if (!this.questions) {
        return [];
      }
      return this.questions.filter(question => question.category === category);
    } else {
      return this.searchResult.filter(question => question.category === category);
    }
  }

  protected startSearch(value: string): void {
    if (!value) {
      this.clearSearch();
      return;
    }
    this.searchValue = value;
    this.searchResult = [];
    const filteredQuestions: IQuestion[] = [];

    for (const question of this.questions) {
      if (question.answer.toLowerCase().includes(value.toLowerCase()) || question.question.toLowerCase().includes(value.toLowerCase())) {
        filteredQuestions.push(question);
      }
      this.searchResult = filteredQuestions;
    }
  }

  protected clearSearch(): void {
    this.searchValue = '';
    this.searchResult = undefined;
    this.getCategories();
  }

  protected handleCategoryClicked(category: CategoryEnum, index: number): void {
    if (this.allTrue() && this.allTrueInitially) {
      this.allTrueInitially = false;
      for (const key of this.keys) {
        this.categories[key] = false;
      }
    }

    this.categories[category] = !this.categories[category];
    this.toggleSelectedCategory(category);
    this.toggleTileSelected(index);

    if (this.allFalse()) {
      this.allTrueInitially = true;
      for (const key of this.keys) {
        this.categories[key] = true;
      }
    }
  }

  private getHelpTopicsForCategories(): void {
    this.helpTopicsService
      .fetchHelpTiles()
      .pipe(
        mergeMap((tiles: TopicsTileInterface[]) => tiles),
        filter((tile: TopicsTileInterface) => this.categories[tile.title] === true),
        toArray(),
        tap((filteredTiles: TopicsTileInterface[]) => {
          this.tiles = filteredTiles;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private getCategories(): void {
    if (this.questions !== undefined) {
      this.categories = [];
      for (const value in CategoryEnum) {
        if (this.questions.filter(question => question.category === CategoryEnum[value]).length !== 0) {
          this.categories[CategoryEnum[value]] = true;
        }
      }
    }
    this.keys = Object.keys(this.categories);
  }

  private getCategoriesBySearch(): void {
    if (this.searchResult !== undefined) {
      const categoriesSearch: boolean[] = [];
      for (const value in CategoryEnum) {
        if (this.searchResult.filter(question => question.category === CategoryEnum[value]).length !== 0) {
          categoriesSearch[CategoryEnum[value]] = this.categories[CategoryEnum[value]] === undefined ? true : this.categories[CategoryEnum[value]];
        }
      }
      this.categories = categoriesSearch;
      this.keys = Object.keys(this.categories);
    }
  }

  private toggleTileSelected(index: number): void {
    this.tiles[index].selected = !this.tiles[index].selected;
  }

  private allTrue(): boolean {
    let allTrue = true;
    for (const key of this.keys) {
      allTrue = allTrue && this.categories[key];
    }
    return allTrue;
  }

  private allFalse(): boolean {
    let allFalse = true;
    for (const key of this.keys) {
      allFalse = allFalse && !this.categories[key];
    }
    return allFalse;
  }

  private toggleSelectedCategory(category: CategoryEnum): void {
    const index = this.selectedCategories.indexOf(category);
    if (index === -1) {
      this.selectedCategories.push(category);
    } else {
      this.selectedCategories.splice(index, 1);
    }
  }
}
